import React, {useState} from 'react';
import {useProducts, clearChanges, deleteLines, updateQuantitys} from '../../context/products/ProductsState';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Loader } from "@progress/kendo-react-indicators";


const Buttons = (props) => {
    
    // eslint-disable-next-line
    const [productsState, productsDispatch] = useProducts();
    
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const PopUpDelete = () => {
        setShowPopUpDelete(!showPopUpDelete);
    }

    const [showPopUpUpdate, setShowPopUpUpdate] = useState(false);
    const PopUpUpdate = () => {
        setShowPopUpUpdate(!showPopUpUpdate);
    }

    const cancel = () => {
        clearChanges(productsDispatch);
    }

    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const save = () => {
        setIsLoadingUpdate(true)
        PopUpUpdate();
        updateQuantitys(productsDispatch, {"lines" : props.products}, props.data.search, props.sort[0], props.data.days).then(val => {
            setIsLoadingUpdate(val)
        });
    }

    const selectdLinesId = Object.keys(props.selectedProducts).filter((key) => props.selectedProducts[key]);
    const [isLoadingRemove, setIsLoadingRemove] = useState(false)
    const remove = () => {
        setIsLoadingRemove(true)
        PopUpDelete();
        props.setSelectedState({});
        // remove loader after delete finish
        deleteLines(productsDispatch,{"order_lines" : selectdLinesId }, props.data.search, props.data.days).then(val => {
            setIsLoadingRemove(val)
        });
    }

    return (
        <div className='buttons'>
            <Tooltip anchorElement="target" position="top">
                <span className="wrrapsBtn" title={!selectdLinesId.length ? 'לא נבחרו שורות' : ''}>
                    <button  className='btn remove' onClick={PopUpDelete} disabled={!selectdLinesId.length || isLoadingRemove}>
                        {isLoadingRemove ?  
                            <Loader type="pulsing"/> :
                            [<i key={1} className="fas fa-trash-alt"></i> , "מחיקה"]
                        }
                    </button>
                </span>
                <span className="wrrapsBtn" title= {!props.products.length ? 'לא נבחרו מוצרים' : ''}>
                    <button  className='btn save' onClick={PopUpUpdate} disabled={!props.products.length || isLoadingUpdate}>
                        {isLoadingUpdate ?  
                            <Loader type="pulsing"/> :
                            [<i key={2} className="fas fa-save"></i>, "שמירה"]
                        }
                    </button>
                </span>
                <button title='איפוס שינויים' className='btn cancel' onClick={cancel}><i className="fas fa-redo-alt"></i></button>
            </Tooltip>
            {showPopUpDelete && 
                <Dialog title= {"אישור הסרת מוצרים."} onClose={PopUpDelete} className="pop-up">
                    <p>המוצרים יוסרו מכל ההזמנות המסומנות.</p>               
                    <DialogActionsBar>
                        <button className='cancel btn' onClick={PopUpDelete}>ביטול</button>
                        <button className='submit btn' onClick={remove}>אישור</button>
                    </DialogActionsBar>
                </Dialog>
            }
            {showPopUpUpdate && 
                <Dialog title = {"עדכון כמויות חדשות."} onClose={PopUpUpdate} className="pop-up">
                    <p>לאחר העדכון תוכל להמשיך לערוך את הכמויות.</p>
                    <DialogActionsBar>
                        <button className='cancel btn' onClick={PopUpUpdate}>ביטול</button>
                        <button className='submit btn' onClick={save}>אישור</button>
                    </DialogActionsBar>
                </Dialog>
            }
        </div>
    )
}
export default Buttons;