import React, { useState } from 'react';
import { useOrders, createNote, sendMessage, removeCurrent } from '../../context/orders/OrdersState';
import CartItem from './CartItem';

const OrderItem = () => {
    const [ordersState, ordersDispatch] = useOrders();
    const { current, notes, products, sendMessages} = ordersState;
    
    const sendClick = e => {
         sendMessage(ordersDispatch, current.id, document.getElementById('inputText').value);
         document.getElementById('inputText').value = '';
    };

    const noteClick = e => {
        createNote(ordersDispatch, current.id, {'text': document.getElementById('inputText').value});  
        document.getElementById('inputText').value = '';
    }

    const [tab, setTab] = useState('notes');

    return (current ?
        <div className='card'>
            <div className='close' onClick={() => removeCurrent(ordersDispatch)}><i className="fas fa-times"></i></div>
            <div className='card_wrapper'>
                <div className='card_tabs'>
                    <button  className={tab === 'notes' ? 'card_tabsBtn active' : 'card_tabsBtn inactive'} onClick={() => { setTab('notes'); }}>
                        {/* <img src={notesIcon} alt='notes-icon'/> */}
                        <svg id="Group_192" data-name="Group 192" width="22.864" height="20.398" viewBox="0 0 22.864 20.398">                       
                                <path id="Path_72" data-name="Path 72" d="M2.974,12.444H1V1H16.8V3.081" transform="translate(1.215 1.158)" fill="none" stroke="#bad564" strokeWidth="2"/>
                                <path id="Path_73" data-name="Path 73" d="M7,7v9.7h7.5v3.086L17.581,16.7h3.527V7Z" transform="translate(-0.452 -0.452)" fill="none" stroke="#bad564" strokeWidth="2"/>      
                        </svg>
                        עדכונים
                    </button>
                    <button className={tab === 'products' ? 'card_tabsBtn cart active' : 'card_tabsBtn cart inactive'}  onClick={() => { setTab('products'); }}>
                        {/* <img src={shoppingIcon} alt='notes-icon'/> */}
                        <svg width="21.525" height="23.709" viewBox="0 0 21.525 23.709">
                            <g id="add-to-cart" transform="translate(0.251 0.25)">
                                <path id="Path_34" data-name="Path 34" d="M218.432,132.006l-2.06,2.06a.68.68,0,0,1-.962,0l-2.06-2.06a.68.68,0,1,1,.962-.962l.9.9v-2.728a.68.68,0,0,1,1.36,0v2.728l.9-.9a.68.68,0,0,1,.962.962Zm0,0" transform="translate(-207.269 -122.709)" fill="#bad564" stroke="#bad564" strokeWidth="0.5"/>
                                <path id="Path_35" data-name="Path 35" d="M.68,16.023H13.552a1.358,1.358,0,0,0,1.308-1.2l.033-.358H3.271A2.728,2.728,0,0,1,.608,12.038l-.6-6.57A2.316,2.316,0,0,1,2.344,2.913h13.6l.044-.481A2.693,2.693,0,0,1,18.587,0h1.758a.68.68,0,0,1,0,1.36H18.587a1.323,1.323,0,0,0-1.245,1.195l-.1,1.107h0L16.215,14.951a2.728,2.728,0,0,1-2.663,2.432H.68a.68.68,0,0,1,0-1.36ZM2.344,4.273a.957.957,0,0,0-.979,1.072l.6,6.57a1.358,1.358,0,0,0,1.309,1.2H15.017l.8-8.837Zm0,0" transform="translate(0)" fill="#bad564" stroke="#bad564" strokeWidth="0.5"/>
                                <path id="Path_36" data-name="Path 36" d="M338.14,417.73A2.137,2.137,0,1,1,336,419.867,2.139,2.139,0,0,1,338.14,417.73Zm0,2.913a.777.777,0,1,0-.777-.777A.778.778,0,0,0,338.14,420.644Zm0,0" transform="translate(-334.484 -398.795)" fill="#bad564" stroke="#bad564" strokeWidth="0.5"/>
                                <path id="Path_37" data-name="Path 37" d="M145.344,417.73a2.137,2.137,0,1,1-2.137,2.137A2.139,2.139,0,0,1,145.344,417.73Zm0,2.913a.777.777,0,1,0-.777-.777A.777.777,0,0,0,145.344,420.644Zm0,0" transform="translate(-132.947 -398.795)" fill="#bad564" stroke="#bad564" strokeWidth="0.5"/>
                            </g>
                        </svg>

                        עגלת קניות
                    </button>
                </div>

                {(tab === 'notes' && sendMessages) &&
                    <div className='card_top'>
                        <div className='card_text'>
                            <textarea id='inputText' type="text" name="name" placeholder='כתוב כאן...'></textarea>
                        </div>
                        <div className='card_btns' id='divBtnPopup'>
                            <button className='btnPopup btnSendSMS' id='btnSendSMS' onClick={sendClick}><i className="fas fa-sms"></i>שליחת SMS</button>
                            <button className='btnPopup btnAddMessage' id='btnAddMessage' onClick={noteClick}><i className="fas fa-comment-dots"></i>הוספת הודעה</button>
                        </div>
                    </div>
                }

                <div className= {tab === 'notes' ? sendMessages ? 'card_content master notes' : 'card_content notes' : 'card_content products'}>
                    {tab === 'notes' &&
                        <div className='card_comments'>
                            {notes.map((note) => {
                                return <div key={note.id} className='mess_line'>
                                        <div className="note">
                                            <div className="avatar" style={{backgroundColor: note.color, color: note.textColor}}>{note.name}</div>
                                            <div className='mess_text' dangerouslySetInnerHTML={{__html: note.text}} ></div>
                                        </div>
                                        <div className='mess_date'>{note.date}</div>
                                    </div>; 
                            })}
                        </div>
                    }
                    {tab === 'products' &&
                        <div className='card_comments'>
                            {products.map((product) =>
                                <CartItem key={product.id} item={product} />
                            )}
                        </div>
                    }
                </div>



            </div>
        </div> : false
        
    )
}

export default OrderItem
