import {
  GET_ORDERS,
  GET_MOBILE_ORDERS,
  GET_ORDERS_FOR_EXCEL,
  CREATE_ORDER,
  GET_DEPARTMENTS,
  SET_CURRENT_ORDER,
  REMOVE_CURRENT_ORDER,
  UPLOAD_ORDERS,
  SUBMIT_ORDER,
  TODAY_CLICKED,
  UPDATE_ORDER,
  GET_ORDER_NOTES,
  LOAD_ORDER,
  HIDE_SUCCESS_POP_UP,
  HIDE_FORBIDDEN_POP_UP,
  TOGGLE_STATUS,
  UPDATE_PRODUCT,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  DUPLICATE_ORDER,
  GET_PL_PRODUCTS,
  ORDER_LOOKUP,
  CLEAR_SUCCESS,
  ORDERS_ERROR,
  TOGGLE_DEP,
  TOGGLE_DAY,
  TOGGLE_LINE,
  FILTER_PRODUCTS_BY_FAMILY,
  SET_ORDER_SEARCH_BY_FAMILY,
  SET_ORDER_SEARCH,
  ADD_ERROR,
  ADD_SUCCESS,
  CLEAR_ERRORS,
  FILTER_PRODUCTS_BY_TEXT,
  FILE_UPLOAD,
  UPDATE_NOT_NOW_CREATE,
  CREATE_ORDER_NOTE,
  SET_LOADING,
  SEND_SMS,
  UPDATE_STATUS,
  CUSTOMERS_POPUP,
  DATES_POPUP,
  EMPTY_ORDERS,
  UPDATE_DELIVERY,
  CLEAR_UPLOAD_MSG,
  SET_ENLARGED,
  ADD_FORBIDDEN_DATE,
  DELETE_FORBIDDEN_DATE,
  UPDATE_MULTIPLE,
  UPDATE_PRODUCT_ERROR,
  SET_SEARCH,
  SET_SORT,
  CHECK_DELIVERY_DATE,
  SET_SEARCHING

} from "../types";

const ordersReducer = (state, action) => {
  //console.log(state);

  const dayNames = [
    "ראשון",
    "שני",
    "שלישי",
    "רביעי",
    "חמישי",
    "שישי",
    "שבת",
  ];

  switch (action.type) {
    case ORDER_LOOKUP:
      const todayDate = `${("0" + new Date().getDate()).slice(-2)}.${(
        "0" +
        (new Date().getMonth() + 1)
      ).slice(-2)}.${new Date().getFullYear()}`.toString();

      

      // notice: lines can be empty
      let lines = action.payload.lookup_lines ?? [];
      lines.map((l) => (l.active = 1));
      console.log(action.payload);
      
      return {
        ...state,
        departments: action.payload.lookup_departments,
        statuses: action.payload.lookup_order_statuses,
        families: action.payload.lookup_product_families,
        columns: action.payload.gridSettings.orders.columns,
        showPrices: action.payload.gridSettings.orders.columns.includes('amount_includes_vat'),
        lines: lines,
        days: action.payload.extraData.days,
        years: action.payload.extraData.years,
        customerId: action.payload.extraData.customerId,
        openCustomers: action.payload.extraData.openCustomers,
        sendMessages: action.payload.extraData.sendMessages,
        statusChange: action.payload.extraData.statusChange,
        cancelledStatus: action.payload.extraData.cancelledStatus,
        noNeedStatus: action.payload.extraData.noNeedStatus,
        missingNoteType: action.payload.extraData.missingNoteType,
        sentStatus: action.payload.extraData.sentStatus,
        draftStatus: action.payload.extraData.draftStatus,
        deletedStatus: action.payload.extraData.deletedStatus,
        actions: action.payload.gridSettings.orders.actions,
        labels: action.payload.labels,
        widths: action.payload.widths,
        existingOrder: action.payload.extraData.existingOrder,
        forbiddenDates: action.payload.lookup_forbidden_dates,
        ordersSearch: {...state.ordersSearch, days: 
        action.payload.extraData.days.map(day => day.date != todayDate ? day : {
          active: 1,
          date: todayDate,
          week: "this",
          day: dayNames[new Date().getDay()],
        }), lines: lines}
      };
    case GET_ORDERS:
      if (state.searching) {
        return {
          ...state,
          orders: action.payload.rows,
          count: action.payload.count,
          createdNow: false,
          statusNow: false,
          searching: false,
          currentOrder: null,
          products: [],
          plProducts: [],
          notes: []
        };
      } else {
        return state;
      }
    case SET_SEARCH: 
      return {...state,
              ordersSearch: action.payload
             };  
    case SET_SORT: 
    return {...state,
            ordersSort: action.payload
          };  
    case GET_MOBILE_ORDERS:
      return {
        ...state,
        orders:
          state.orders.length > action.payload.length
            ? state.orders
            : [...state.orders, ...action.payload.data.rows],
        count: action.payload.data.count,
        createdNow: false,
        statusNow: false,
        searching: false
      };
    case EMPTY_ORDERS: {
      return {
        ...state,
        orders: [],
      };
    }
    case GET_ORDERS_FOR_EXCEL:
      return {
        ...state,
        allOrdersForExcel: action.payload.rows,
      };
    case DUPLICATE_ORDER:
      console.log(action.payload);
      return {
        ...state,
        // orders: [action.payload, ...state.orders],
        currentOrder: action.payload,
        count: state.count + 1,
        createdNow: true,
        success: "ההזמנה שוכפלה בהצלחה - הינך מעבר לעריכה",
      };
    case FILE_UPLOAD:
      console.log(action.payload);
      return {
        ...state,
        currentOrder: {
          actions: state.currentOrder.actions,
          fields: action.payload.order,
          max_orders_monthly: state.currentOrder.max_orders_monthly,
          max_order_weekly: state.currentOrder.max_order_weekly,
          budget_left: state.currentOrder.budget_left,
          priorityTotal: state.currentOrder.priorityTotal,
        },
        total: action.payload.order.amount_includes_vat ?? 9999,
        success: "הקובץ עלה בהצלחה",
        uploadMessages: action.payload.messages ?? [],
      };
    case UPLOAD_ORDERS:
      return {
        ...state,
        success: "ההזמנות נוספו בהצלחה",
        count: action.payload.data.count,
        orders: action.payload.data.rows,
        uploadMessages: action.payload.messages ?? [],
        updatedNow: true,
      };
    case FILTER_PRODUCTS_BY_TEXT:
      return {
        ...state,
        orderSearchFamily: 0,
        plProducts: action.payload ?? [],
      };
    case FILTER_PRODUCTS_BY_FAMILY:
      return {
        ...state,
        orderSearchText: "",
        plProducts: action.payload ?? [],
      };
    case SET_ORDER_SEARCH_BY_FAMILY:
      console.log(action.payload);
      return {
        ...state,
        orderSearchFamily: action.payload,
      };
    case SET_ORDER_SEARCH:
      return {
        ...state,
        orderSearchText: action.payload,
      };
    case LOAD_ORDER:
      return {
        ...state,
        currentOrder: action.payload,
        total: action.payload.fields.amount_includes_vat,
        customerName: action.payload.customerName,
        deliveryDate: action.payload.date_format,
        possibleDates: action.payload.possible_dates, // possible dates to change order delivery
        deliveryDateUnFormat: action.payload.date_unformat, // delivery date not formatted - value for changing order date
        showPrices: action.payload.show_prices,
        submittedNow: false,
        updatedNow: false,
      };
    case CREATE_ORDER:
      return {
        ...state,
        currentOrder: action.payload,
        showCustomersPopup: false,
        createdNow: true,
        submittedNow: false,
        updatedNow: false,
        customerId: null,
      };
    case SUBMIT_ORDER:
     
      let current = { ...state.currentOrder, 
                     date_format: action.payload.data.date_format,
                     date_unformat: action.payload.data.date_unformat
                    };
      console.log("current: ", current);
      return {
        ...state,
        successPopUp: true,
        lastSubmitted: current,
        currentOrder: null,
        submittedNow: true,
        products: [],
        plProducts: [],
        notes: []
      };
    case HIDE_SUCCESS_POP_UP:
      return {
        ...state,
        successPopUp: false,
        updatedNow: false,
      };
    case HIDE_FORBIDDEN_POP_UP:
        return {
          ...state,
          forbiddenNotice: 0
    };

    case UPDATE_ORDER:
      console.log(action.payload)
      return {
        ...state,
        successPopUp: true,
        currentOrder: null,
        updatedNow: true,
        products: [],
        plProducts: [],
        notes: []
      };
    case CHECK_DELIVERY_DATE:
      console.log("valid date " + action.payload.valid + "!!");
      console.log("returning " + (action.payload.valid ? 2 : 1));
      return {
        ...state,
        forbiddenNotice: action.payload.valid ? 2 : 1  
      }
    
    case UPDATE_MULTIPLE:
      return {
        ...state,
        updatedNow: true,
      };
    case UPDATE_NOT_NOW_CREATE:
      return {
        ...state,
        createdNow: false,
      };
    case GET_PL_PRODUCTS:
      console.log(action.payload);
      return {
        ...state,
        products: action.payload.rows.filter(
          (product) => +product.quantity > 0
        ),
        plProducts: action.payload.rows,
        relevantFamilies: action.payload.relevantFamilies,
        relevantDeps: action.payload.relevantDeps,
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        actions:action.payload.gridSettings.orders.actions,
        departments: action.payload.lookup_departments,
        sendMessages: action.payload.extraData.sendMessages,
        families: action.payload.lookup_product_families,
      };
    case UPDATE_PRODUCT:
      // if we have order we get the total from the order. else - calculate by quantity diff
      let newTotalUpdate;
      console.log(action.payload);
      if (action.payload.order) {
        console.log("newTotalUpdate");
        newTotalUpdate = action.payload.order.amount_includes_vat;
        console.log(newTotalUpdate);
      } else {
        newTotalUpdate =
          +state.total +
          (action.payload.product.quantity - action.payload.oldQuantity) *
            +action.payload.product.price_includes_vat;
        console.log("else - newTotalUpdate");
        console.log(newTotalUpdate);
      }

      return {
        ...state,
        /*products: state.products.map((product) =>
                            product.id === action.payload.product.id ? action.payload.product : product
                        ),*/
        total: newTotalUpdate < 0 ? 0 : newTotalUpdate,
      };
    case ADD_PRODUCT:
      const newTotalAdd =
        +state.total +
        +action.payload.product.price_includes_vat *
          +action.payload.product.quantity;

      return {
        ...state,
        // success: "המוצר נוסף בהצלחה"
        products: [action.payload.product, ...state.products],
        total: newTotalAdd < 0 ? 0 : newTotalAdd,
      };
    case DELETE_PRODUCT:
      console.log(action.payload);
      return {
        ...state,
        products: state.products.filter(
          (item) => item.id !== action.payload.productId
        ),
        total: action.payload.order.amount_includes_vat,
      };
    case SET_CURRENT_ORDER:
      return {
        ...state,
        current: action.payload,
      };
    case REMOVE_CURRENT_ORDER:
      return {
        ...state,
        current: "",
      };
    case ORDERS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_PRODUCT_ERROR:
      
      // calc new order total
      console.log(action.payload.product);
      let quantity = action.payload.product.quantity;
      let orderTotal = state.total - (action.payload.product.price_includes_vat * (quantity ? quantity : 1));
      let newProduct = {...action.payload.product, quantity: 0};
      
      return {
        ...state,
        error: 'קיימת בעיה בשמירת המוצר: '   + newProduct.description,
        products: state.products.filter(
          (item) => item.id !== action.payload.product.id
        ),
        plProducts: state.plProducts.map((plProduct) =>
          plProduct.id === newProduct.id ? newProduct : plProduct
        ),
        total: orderTotal
      }
    case TOGGLE_DEP:
      return {
        ...state,
        departments: state.departments.map((dep) =>
          dep.id === action.payload.id ? action.payload : dep
        ),
      };
    case TOGGLE_DAY:
      return {
        ...state,
        days: state.days.map((day) =>
          day.date === action.payload.date ? action.payload : day
        ),
      };
    case TOGGLE_LINE:
      return {
        ...state,
        lines: state.lines.map((line) =>
          line.date === action.payload.id ? action.payload : line
        ),
      };
    case TOGGLE_STATUS:
      return {
        ...state,
        statuses: state.statuses.map((status) =>
          status.id === action.payload.id ? action.payload : status
        ),
      };
    case GET_ORDER_NOTES:
      return {
        ...state,
        notes: action.payload,
      };
    case SEND_SMS:
    case CREATE_ORDER_NOTE:
      return {
        ...state,
        notes: [action.payload, ...state.notes],
      };
    case TODAY_CLICKED:
      return {
        ...state,
        todayWasClicked: true,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
        success: "",
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: "",
      };
    case ADD_SUCCESS:
      return {
        ...state,
        success: action.message,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_ERROR:
      return {
        ...state,
        error: action.message,
      };
    case UPDATE_STATUS:
      let id = action.payload.id;
      let newStatus = action.payload.status;
      let newOrders = [];
      if (newStatus === state.deletedStatus) {
        newOrders = state.orders.filter(function (order) {
          return parseInt(order.id) !== id;
        });
      } else {
        newOrders = state.orders.map((obj) => {
          if (parseInt(obj.id) === id) {
            return { ...obj, status: newStatus };
          }
          return obj;
        });
      }
      return {
        ...state,
        orders: newOrders,
        statusNow: true,
      };
    case UPDATE_DELIVERY:
      let ud_id = action.payload.id;

      // set new date fields
      let newDelivery = action.payload.delivery_date;
      let newDay = new Date(newDelivery).getDay();
      
      let newDayName = dayNames[newDay];
      let newDeliverySplit = newDelivery.split("-");
      newDelivery =
        newDeliverySplit[2] +
        "-" +
        newDeliverySplit[1] +
        "-" +
        newDeliverySplit[0];

      let ud_newOrders = [];

      ud_newOrders = state.orders.map((obj) => {
        if (parseInt(obj.id) === ud_id) {
          console.log(obj);
          return {
            ...obj,
            delivery: newDelivery,
            delivery_day: newDay,
            day: newDayName,
          };
        }
        return obj;
      });

      return {
        ...state,
        orders: ud_newOrders,
        currentOrder: {
          ...state.currentOrder,
          date_unformat: action.payload.delivery_date,
          date_format: newDelivery,
        },
      };
    case CUSTOMERS_POPUP:
      return {
        ...state,
        showCustomersPopup: action.payload,
      };
    case DATES_POPUP:
      return {
        ...state,
        showForbiddenDatesPopup: action.payload,
      };
    case CLEAR_UPLOAD_MSG:
      return {
        ...state,
        uploadMessages: [],
      };
    case SET_ENLARGED:
      return {
        ...state,
        enlarged: action.payload,
      };
    case ADD_FORBIDDEN_DATE:
        
        if (action.payload === '') {
            const newRecord = {
                id: Math.max(...state.forbiddenDates.map(o => o.id), 0) + 1,//state.forbiddenDates.length + 1,
                name: action.payload
                };
                console.log(newRecord);
            return {
                ...state,
                forbiddenDates: [newRecord, ...state.forbiddenDates]
            }    
        } else {
            let temp = action.payload.split('/'); 
            let new_date = temp[2] + '-' + temp[1] + '-' + temp[0];

            const newRecord = {
                id: Math.max(...state.forbiddenDates.map(o => o.id), 0),
                name: new_date
            };

            console.log(newRecord);
            return {
            ...state,
                forbiddenDates: state.forbiddenDates.map((f_date) =>
                f_date.name === '' ? newRecord : f_date)
            }
        }
    case DELETE_FORBIDDEN_DATE:
        console.log(state.forbiddenDates);
        return {
            ...state,
            forbiddenDates: state.forbiddenDates.filter((f_date) =>
            f_date.name !== action.payload)
        }
    case SET_SEARCHING:
      return {
        ...state,
        searching: action.payload
      }
    default:
      return state;
  }
};

export default ordersReducer;
