import React, {useState, useEffect, useContext, useCallback} from 'react';
import { Grid, GridNoRecords, GridColumn as Column, getSelectedState } from "@progress/kendo-react-grid";
import AlertContext from '../../context/alert/alertContext';
import { useProducts, getProducts, getLookup, toggleDay, clearErrors, clearSuccess} from '../../context/products/ProductsState';
import Slider from "react-slick";
import Quantity from './Quantity';
import { getter } from "@progress/kendo-react-common";
import { Loader } from "@progress/kendo-react-indicators";
import  Buttons  from "./PopUps&Buttons";
const DATA_ITEM_KEY = "order_line_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const Products = () => {

    const [productsState, productsDispatch] = useProducts();
    const { products, columns, labels, error, days, success, loading } = productsState;

    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const createState = (search, days) => {
        return {
            search: search,
            days: days
        };
    };

    const initialSort = [
        {
            field: "order_line_id",
            dir: "desc",
        },
    ];

    const [selectedState, setSelectedState] = useState({});
    const onSelectionChange = useCallback(
        (event) => {
            if(!loading){
                const newSelectedState = getSelectedState({
                    event,
                    selectedState: Object.keys(selectedState) && selectedState,
                    dataItemKey: DATA_ITEM_KEY,
                });
                setSelectedState(newSelectedState);
            }
        },
        // eslint-disable-next-line
        [selectedState]
    );

    const onHeaderSelectionChange = useCallback((event) => {
        if(!loading ){
            const checkboxElement = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState = {};
            event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
        }    
    // eslint-disable-next-line
    }, []);

    function SampleNextArrow(props) {
        const {onClick } = props;
        return (
          <div className='prevArrow'>
            <i className='fas fa-chevron-right' onClick={onClick} />
          </div>
        );
      }
    
    function SamplePrevArrow(props) {
        const {onClick} = props;
        return (
            <div className='nextArrow'>
            <i className='fas fa-chevron-left' onClick={onClick} />
            </div>
        );
    }


    // settings slider
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        focusOnSelect: false,
        initialSlide: ([...days].reverse()).findIndex(day => day.week === 'this')
    };

    const [state, setState] = useState(
        createState('', [])
    )
    
     const [sort, setSort] = useState(initialSort);

    const pageChange = (event) => {
        setState(createState(state.search, state.days));
    };

    const onChange = e => {
        setState(createState(e.target.value, state.days));
    }

    const sortClick = (event) => {
        setSort(event.sort);
    };

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const searchByDay = (day) => {
        if (day.active === 1) {
            day.active = 0;
            toggleDay(productsDispatch, day);
        } else {
            day.active = 1;
            toggleDay(productsDispatch, day);
        }   
        setState(createState(state.search, days));
    };

    useEffect(() => {
        getLookup(productsDispatch);
    }, [productsDispatch]);

    useEffect(() => {
        getProducts(productsDispatch, state.search, sort[0], state.days);
    }, [productsDispatch, state.search, sort, state.days]);

    useEffect(() => {
        if (success !== '' && success !== null) {
          setAlert(success, 'success');
          clearSuccess(productsDispatch);
        }
    // eslint-disable-next-line
    }, [success, productsDispatch]);

    useEffect(() => {
        if (error !== ''&& error !== null) {
            setAlert(error, 'danger');
            clearErrors(productsDispatch);
        }      
    // eslint-disable-next-line
    }, [error, productsDispatch]);

    useEffect(() => {
        if (typeof error !== 'undefined' && 
            error !== null && 
            typeof error.data !== 'undefined' &&
            error.data.status === 401) {
            window.location.reload();
        }
    }, [error]);



    return(
        <div className='productsPage divAllPage'>
            <div className='top d-flex'>
                <div className='search_wrapper'>
                    <form onSubmit={onSubmit}>
                        <div className='searchNameId'>
                            <i className="fas fa-search"></i>
                            <input className='nameIdInput' type="text" placeholder='הקלידו מק"ט / שם מוצר' onChange={onChange} />
                        </div>
                    </form>
                    {days.findIndex(day => day.week === 'this') !== -1 && 
                        <div className='days'>
                            <ul>
                            {days &&
                                <Slider  {...settings}   >
                                    {[...days].reverse().map((day) => (
                                        <li
                                        key={day.date}
                                        className={(day.active === 1) ? 'day-active' : 'day'}
                                        onClick={() => {
                                            searchByDay(day);
                                        }}
                                        >
                                        <span className='dayInWeek' >{day.day}</span>
                                        <span className='dateInMonth'>
                                            {' '}
                                            {day.date.substring(0, 10).split('.')[0]}.
                                            {day.date.substring(0, 10).split('.')[1]}
                                        </span>
                                        </li>
                                    ))}
                                </Slider>
                            }
                            </ul>
                        </div>               
                    }
                </div>
                <Buttons data={state} products={products} sort={sort} selectedProducts={selectedState} setSelectedState={setSelectedState}/>
            </div>
            
            <div className={loading ? 'loading divWarpGrid_wrapper' : 'divWarpGrid_wrapper'} dir='rtl'>
                {loading && <Loader type="infinite-spinner"/>}
                <Grid
                    data={products.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: true,
                        cell: false,
                        mode: "multiple",
                    }}                     
                    sort={sort}
                    onPageChange={pageChange}
                    sortable={true}
                    onSortChange={sortClick}          
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}              
                >
                    <GridNoRecords>
                       {state.search === "" ?                         
                            <div>
                                <i className="fas fa-shopping-cart"></i>   לא נבחרו מוצרים 
                            </div>: 
                            <div>
                                <i className="fas fa-search"></i>   לא נמצאו תוצאות
                            </div>
                        }
                    </GridNoRecords>       
                    <Column
                        key="select"
                        field={SELECTED_FIELD}
                        headerSelectionValue={
                            products.findIndex((item) => !selectedState[idGetter(item)]) === -1
                        }
                    />         
                    {(columns ?? []).map((column) =>
                    column !== 'quantity' ?
                        <Column key={column} field={column} title={labels[column]} /> :
                        <Column key={column} field={column} title={labels[column]} cell={Quantity} />
                    )}
                </Grid>
            </div>
        </div>
    )

}

export default Products