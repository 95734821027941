export const CHANGE_COLOR = 'CHANGE_COLOR';
// auth
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const OTP_SUCCESS = 'OTP_SUCCESS';
export const OTP_FAIL = 'OTP_FAIL';
export const LOGOUT = 'LOGOUT';
export const RETURN_STEP_1 = 'RETURN_STEP_1';
export const SWITCH_SUCCESS = 'SWITCH_SUCCESS';
export const SWITCH_FAIL = 'SWITCH_FAIL';
export const SET_LOADING_BETWEEN_STEPS = 'SET_LOADING_BETWEEN_STEPS';

//customers
export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const FILTER_CUSTOMERS = 'FILTER_CUSTOMERS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';
export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const CLEAR_CURRENT_CUSTOMER = 'CLEAR_CURRENT_CUSTOMER';
export const CLEAR_CUSTOMERS = 'CLEAR_CUSTOMERS';
export const CUSTOMERS_LOOKUP = 'CUSTOMERS_LOOKUP';
export const CUSTOMERS_ERROR = 'CUSTOMERS_ERROR';
export const GET_CUSTOMER_NOTES = 'GET_CUSTOMER_NOTES';
export const GET_CUSTOMER_ORDER_NOTES = 'GET_CUSTOMER_ORDER_NOTES';
export const CREATE_CUSTOMER_NOTE = 'CREATE_CUSTOMER_NOTE';
export const SEND_SMS = 'SEND_SMS';
export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';

// orders
export const ORDER_LOOKUP = 'ORDER_LOOKUP'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_MOBILE_ORDERS = 'GET_MOBILE_ORDERS'
export const CREATE_ORDER = 'CREATE_ORDER'
export const EMPTY_ORDERS='EMPTY_ORDERS'
export const SET_CURRENT_ORDER = 'SET_CURRET_ORDER'
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const SET_CURRENT_DEPARTMENT = 'SET_CURRENT_DEPARTMENT'
export const ORDERS_ERROR = 'ORDERS_ERROR';
export const LOAD_ORDER = 'LOAD_ORDER';
export const GET_PL_PRODUCTS = 'GET_PL_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const TOGGLE_DEP = 'TOGGLE_DEP';
export const TOGGLE_DAY = 'TOGGLE_DAY';
export const TOGGLE_LINE = 'TOGGLE_LINE';
export const TOGGLE_STATUS = 'TOGGLE_STATUS';
export const FILTER_PRODUCTS_BY_FAMILY = 'FILTER_PRODUCTS_BY_FAMILY';
export const FILTER_PRODUCTS_BY_TEXT = 'FILTER_PRODUCTS_BY_TEXT';
export const GET_ORDER_NOTES = 'GET_ORDER_NOTES';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const UPLOAD_ORDERS = 'UPLOAD_ORDERS';
export const UPDATE_NOT_NOW_CREATE = 'UPDATE_NOT_NOW_CREATE';
export const CREATE_ORDER_NOTE = 'CREATE_ORDER_NOTE';
export const DUPLICATE_ORDER = 'DUPLICATE_ORDER';
export const REMOVE_CURRENT_ORDER = 'REMOVE_CURRENT_ORDER';
export const REMOVE_CURRENT_CUSTOMER = 'REMOVE_CURRENT_CUSTOMER';
export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const GET_ORDERS_FOR_EXCEL = 'GET_ORDERS_FOR_EXCEL';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const HIDE_SUCCESS_POP_UP = 'HIDE_SUCCESS_POP_UP';
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY';
export const CLEAR_UPLOAD_MSG = 'CLEAR_UPLOAD_MSG';
export const SET_ENLARGED = 'SET_ENLARGED';
export const ADD_FORBIDDEN_DATE = 'ADD_FORBIDDEN_DATE';
export const DELETE_FORBIDDEN_DATE = 'DELETE_FORBIDDEN_DATE';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SORT = 'SET_SORT';

export const UPDATE_MULTIPLE='UPDATE_MULTIPLE'
//export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
//export const REGISTER_FAIL = 'REGISTER_FAIL';
export const SET_ORDER_SEARCH = 'SET_ORDER_SEARCH';
export const SET_ORDER_SEARCH_BY_FAMILY = 'SET_ORDER_SEARCH_BY_FAMILY';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const ADD_ERROR = 'ADD_ERROR';
export const ADD_SUCCESS = 'ADD_SUCCESS';
export const SET_LOADING = 'SET_LOADING';
export const TODAY_CLICKED = 'TODAY_CLICKED';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const CUSTOMERS_POPUP='CUSTOMERS_POPUP'
export const DATES_POPUP='DATES_POPUP'
export const CHECK_DELIVERY_DATE = 'CHECK_DELIVERY_DATE'
export const HIDE_FORBIDDEN_POP_UP = 'HIDE_FORBIDDEN_POP_UP'
export const SET_SEARCHING = 'SET_SEARCHING'

//products 

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCTS_LOOKUP = 'PRODUCTS_LOOKUP';
export const DELETE_LINES = 'DELETE_LINES';
export const UPDATE_LINES = 'UPDATE_LINES';
export const CLEAR_CHANGES = 'CLEAR_CHANGES';
export const SET_QUENTITY = 'SET_QUENTITY';

//alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';