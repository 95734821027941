import {
    // REGISTER_SUCCES,
    // REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS,
    OTP_SUCCESS,
    OTP_FAIL,
    CLEAR_SUCCESS,
    SWITCH_SUCCESS,
    SWITCH_FAIL,
    RETURN_STEP_1,
    SET_LOADING_BETWEEN_STEPS
} from '../types';

const authReducer = (state, action) => {
    switch (action.type) {
        case USER_LOADED:
            
            return {
                ...state,
                isAuthenticated: (action.payload !== null && action.payload !== []) ? true : false,
                isOtpSent: false,
                loading: false,
                user: action.payload,
                switchedNow: false
            }
        case OTP_SUCCESS:
            return {
                ...state,
                isOtpSent: true,
                loading: false,
                switchedNow: false
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isOtpSent: false,
                loading: false,
                success: "התחברת בהצלחה",
                switchedNow: false
            };
        case SWITCH_SUCCESS:
                localStorage.setItem('token', action.payload.token);                
                return {
                    ...state,
                    ...action.payload,
                    isAuthenticated: true,
                    isOtpSent: false,
                    loading: false,
                    switchedNow: true
                };    
        case SWITCH_FAIL:
            localStorage.setItem('token', action.payload.token);                
            return {
                ...state,
                error: action.payload,
                switchedNow: false
            };            
        case LOGIN_FAIL:
            localStorage.removeItem('token');
        
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isOtpSent: true,
                loading: false,
                user: null,
                error: action.payload
            }    
        case OTP_FAIL:  
            localStorage.removeItem('token');
            
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isOtpSent: false,
                loading: false,
                user: null,
                error: action.payload
            }          
        case LOGOUT:    
            localStorage.removeItem('token');
            
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isOtpSent: false,
                loading: false,
                validate: true,
                user: null,
                switchedNow: false
            }  
        case RETURN_STEP_1:    
            return {
                ...state,
                isOtpSent: false,
                validate: false,
                switchedNow: false
                
            }  
        case SET_LOADING_BETWEEN_STEPS:
            return {
                ...state,
                loadingBetweenSteps: action.payload
        }   
        case AUTH_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                isAuthenticated: false,
                user: null
            }   
        case CLEAR_SUCCESS:
            return {
                ...state,
                success: ''
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: ''
            };
        default:
            return state;
    }
}
export default authReducer;