import axios from 'axios';

const setAuthToken = token => {

    // set some axios defs
    axios.defaults.baseURL = 'https://adhagan.mipo.co.il/backend';

    //axios.defaults.baseURL = 'https://dev-adhagan.mipo.co.il/backend';
    axios.defaults.headers.ContentType = "application/json";

    // set token
    if (token) {
        //axios.defaults.headers.common['x-auth-token'] = token;
        axios.defaults.headers.common['authorization'] = 'Bearer ' + token;
    } else {
        //delete axios.defaults.headers.common['x-auth-token'];
        delete axios.defaults.headers.common['authorization'];
    }
}

export default setAuthToken;