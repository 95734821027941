import React, { useContext, useEffect, useState } from "react";
import shopIcon from "../../images/shop.png";
import cartIcon from "../../images/cart.png";
import noteIcon from "../../images/note.png";
import { Loader } from "@progress/kendo-react-indicators";

import { use } from "react-dom-factories";
import CartItem from "./CartItem";
import {
  getDepartments,
  getPriceListProducts,
  loadOrder,
  useOrders,
} from "../../context/orders/OrdersState";
import { useParams } from "react-router-dom";
import ProductsFilter from "./ProductsFilter";

const OrderMobile = (props) => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("products");
  const { cartProducts, showSendPopUp, deliveryFee, totalWithFee } = props;
  const [ordersState, ordersDispatch] = useOrders();
  const [noteActive, setNoteActive] = useState(true);

  const { total, plProducts, currentOrder, notes, loading, showPrices } = ordersState;
  useEffect(() => {
    loadOrder(ordersDispatch, id);
    getDepartments(ordersDispatch);
    getPriceListProducts(ordersDispatch, id);
  }, [ordersDispatch]);

  const tabs = [
    { name: "products", title: "מוצרים", icon: shopIcon },
    { name: "notes", title: "עדכונים", icon: noteIcon },

    { name: "cart", title: "עגלת קניות", icon: cartIcon },
  ];
  return (
    <div className="orderMobileCont">
      <div className="tabs">
        {tabs.map((tab) => (
          <button
            className={[tab.name === activeTab ? "tab active" : "tab"]}
            onClick={() => {
              setActiveTab(tab.name);
            }}
            key={tab.name}
          >
            {<img src={tab.icon} />}
            {tab.title}
          </button>
        ))}
      </div>

      <div className="tabs_Content">
        {activeTab === "products" && <ProductsFilter />}
        {(activeTab === "products" && noteActive) &&
          <div className="products_disc">
            <div className="close" onClick={() => { setNoteActive(false) }}>&times;</div>
            הנתונים המדויקים מופיעים על גבי המוצר, אין להסתמך על הפירוט המופיע באתר, יתכנו טעויות או אי התאמות, יש לקרוא את המופיע על גבי אריזת המוצר לפני השימוש.
            התמונות והתאריכים המופיעים הינם להמחשה בלבד ואין להסתמך עליהם.
          </div>}
        {activeTab == "products" && (
          <div className={`products_tab ${!noteActive ? "noNoteActive" : ""}`}>

            {plProducts.map((product) => (
              <CartItem key={product.id} item={product} showPrices={showPrices}/>
            ))}
          </div>
        )}
        {activeTab === 'notes' &&
          <div className='card_comments'>
            {notes.map((note) => {
              return <div key={note.id} className='mess_line'>
                <div className="note">
                  <div className="avatar" style={{ backgroundColor: note.color, color: note.textColor }}>{note.name}</div>
                  <div className='mess_text' dangerouslySetInnerHTML={{ __html: note.text }} ></div>
                </div>
                <div className='mess_date'>{note.date}</div>
              </div>;
            })}
          </div>
        }
        {activeTab == "cart" &&
          cartProducts.map((product) => (
            <CartItem key={product.id} item={product} showPrices={showPrices}/>
          ))}
      </div>
      <div className={"total_footer" + (!showPrices ? " no_price" : "")}>
        
        {showPrices &&
                    <><div className="orderSum_desc">
                      <div className="sum-text">
                        <span className="sum-text_subtitle">סה"כ
                        <span className="sum-text_subtitle">  (כולל מע"מ)</span>
                        </span>
                      </div>
                      
                      <div className="sum-number-small">
                        {Math.round(total * 100) / 100}{" "}
                        <i className="fas fa-shekel-sign"></i>
                      </div>
                    </div>
                    <div className="orderSum_desc">
                      <div className="sum-text">
                        <span className="sum-text_subtitle">דמי משלוח
                        </span>
                      </div>
                      <div className="sum-number-small">
                        {Math.round(deliveryFee * 100) / 100}{" "}
                        <i className="fas fa-shekel-sign"></i>
                      </div>
                    </div>
                    <div className="orderSum_desc">
                      <div className="sum-text">
                        <span className="sum-text_title">לתשלום
                        </span>
                      </div>
                      <div className="sum-number">
                      {Math.round(totalWithFee * 100) / 100}{" "}
                        <i className="fas fa-shekel-sign"></i>
                      </div>
                    </div>
                  </>
                  }
        {/* {currentOrder?.actions?.length > 0 &&  */}
        <button onClick={showSendPopUp} className={"send-order-btn" + (loading ? " disabled-btn" : "")} 
                disabled={loading || total == 0}>
          {loading ?
            <Loader type="pulsing" />
            :
            currentOrder?.actions?.indexOf("send-update") > -1
              ? "שליחת עדכון"
              : "שליחת הזמנה"
          }
        </button>
        {/*  } */}
      </div>
    </div>
  );
};

export default OrderMobile;
