import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import Quantity from './Quantity';
import {
    useOrders,
    setEnlarged
} from "../../context/orders/OrdersState";

const Product = ({ product, showPrices }) => {
    const { name, description, price_includes_vat, unit, is_missing, image, back_image } = product;
    const ordersDispatch = useOrders()[1];
    const params = useParams();
    const orderId = params.id;

    const enlargeImg = () => {
        setEnlarged(ordersDispatch, product);
    }

    return (product &&
        <div className={is_missing == 1 ? 'product disabled' : 'product'}>
            <div className='table_pic'>
                {image ?
                    <img width="45px" height="55px" onClick={enlargeImg} src={process.env.PUBLIC_URL + `/images` + image} alt={""}/>
                :
                    <img />}
            </div>
            <div className='table_desc'>
                <div className={is_missing == 1? "main_info missing" : "main_info"}>{description}</div>
            </div>
            <Quantity product={product} orderId={orderId}/>
            <div className='table_unit'>
                <div className={is_missing == 1? "main_info missing" : "main_info"}>{unit}</div>
            </div>
            {showPrices &&
                <div className='table_price'>
                    <div className={is_missing == 1? "main_info missing" : "main_info"}>{price_includes_vat}</div>
                </div>
            }
        </div>
    )
}

export default Product
