import React from 'react';
// import { Navigate} from 'react-router-dom';
import { useAuth } from '../../context/auth/AuthState';
import { Loader } from "@progress/kendo-react-indicators";
import Login from '../../components/auth/Login';

const PrivateRoute = ({element}) => {
    const authState = useAuth()[0];
    const { isAuthenticated, loading } = authState;
    if (isAuthenticated) return element;
    if (loading) return <Loader type="converging-spinner"/>;
    return <Login />;
}

export default PrivateRoute
