import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BrowserView, MobileView, } from 'react-device-detect';
import NavbarOrLogin from './components/layout/NavbarOrLogin';
import Customers from './components/customers/Customers';
import Orders from './components/orders/Orders';
import Products from './components/products/Products';
import NotNeeded from './components/orders/NotNeeded';
import Order from './components/orders/Order';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Alerts from './components/layout/Alerts'
import setAuthToken from './utils/setAuthToken';
import PrivateRoute from './components/routing/PrivateRoute';
import CustomersState from './context/customers/CustomersState';
import AuthState from './context/auth/AuthState';
import OrdersState from './context/orders/OrdersState';
import ProductsState from './context/products/ProductsState';
import AlertState from './context/alert/AlertState';
import BottomNav from './components/layout/bottomNav'
import Container from 'react-bootstrap/Container';
import '@progress/kendo-theme-default/dist/all.css';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import './App.css';
import './App.scss';
import './Rachel.css';
import './Miriam.css';
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {

  return (
    <AuthState>
      <CustomersState>
        <ProductsState>
          <OrdersState>
            <AlertState>
              <Router>
                <div>
                  <BrowserView>
                    <NavbarOrLogin />
                    <Container fluid>

                      <Alerts />
                      <Routes>
                        <Route exact path='/not_needed/:id' element={<PrivateRoute element={<NotNeeded />} />} />
                        <Route exact path='/' element={<PrivateRoute element={<Orders />} />} />
                        <Route exact path='/customers' element={<PrivateRoute element={<Customers />} />} />
                        <Route exact path='/orders' element={<PrivateRoute element={<Orders />} />} />
                        <Route exact path='/products' element={<PrivateRoute element={<Products />} />} />
                        <Route  exact path='/orders/:id' element={<PrivateRoute   element={<Order />} />} />
                        <Route exact path='/register' element={<Register />} />
                        <Route exact path='/login' element={<Login />} />
                      </Routes>

                    </Container>
                    
                  </BrowserView>
                  <MobileView>
                    <NavbarOrLogin />
                    <Container fluid className='mobile_container'>
                      <Alerts />
                      <Routes>
                        <Route exact path='/not_needed/:id' element={<PrivateRoute element={<NotNeeded />} />} />
                        <Route exact path='/login' element={<Login />} />
                        <Route exact path="/" element={<PrivateRoute element={<Orders />} />} />
                        <Route exact path="/orders" element={<PrivateRoute element={<Orders />} />} />
                        <Route exact path="/orders/:id" element={<PrivateRoute element={<Orders />} />} />

                        <Route exact path="/cart/:id" element={<PrivateRoute element={<Order />} />} />

                      </Routes>
                      <BottomNav />
                    </Container>
                    {/* <BottomNav/> */}
                  </MobileView>
                </div>
              </Router>
            </AlertState>
          </OrdersState>
        </ProductsState>
      </CustomersState>
    </AuthState>
  );
}

export default App;
