import React, {useState, useEffect} from 'react';
import { useProducts, setProductQuantity } from '../../context/products/ProductsState';

const Quantity = (props) => {

    const [quantity, setQuantity] = useState(props.dataItem.quantity);

    // eslint-disable-next-line
    const [productsState, productsDispatch] = useProducts();
    const {loading} = productsState;

    const upClick = e => {
        if(parseInt (quantity) < 1000 ){
            setQuantity(++props.dataItem.quantity);  
        }
    }

    const downClick = e => {
        if (parseInt (quantity) > 0) {
            setQuantity(--props.dataItem.quantity); 
        }
    }

    // changing quantity input
    const onChange = e => {
        // update quantity       
        // eslint-disable-next-line 
        if (e.target.value == +(e.target.value) && 
            parseInt (e.target.value) >= 0 &&
            parseInt (e.target.value) <= 1000) {
            setQuantity(e.target.value);   
            props.dataItem.quantity = e.target.value;                 
        }
        else if((e.target.value) === ""){
            setQuantity(0);   
            props.dataItem.quantity = 0;  
        }
    }

    const onClick = e => {
        e.target.select();
    }

    useEffect(() => {
        setProductQuantity(productsDispatch, props.dataItem)       
    // eslint-disable-next-line
    }, [quantity]);

return (
    <td>
        <div className='table_qty'>
            <button disabled={loading} className='plus-btn' onClick={upClick}><i className="fas fa-plus"></i></button>
                <input className='quantity-input' type='number' value={props.dataItem.quantity ?? 0} 
                    onChange={onChange} 
                    onClick={onClick}
                    disabled={loading}
                    max='999' />
            <button disabled={loading} className='minus-btn' onClick={downClick}><i className="fas fa-minus"></i></button>
        </div>
    </td>);
}

export default Quantity;