import React, {useReducer, useContext, useEffect} from 'react';
import AuthContext from './authContext';
import authReducer from './authReducer';
import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';

import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    OTP_SUCCESS,
    OTP_FAIL,
    LOGOUT,
    CLEAR_ERRORS,
    UPDATE_NOT_NOW_CREATE,
    RETURN_STEP_1,
    SWITCH_SUCCESS,
    SWITCH_FAIL,
    CLEAR_SUCCESS,
    SET_LOADING_BETWEEN_STEPS
} from '../types';


export const useAuth = () => {
    const { state, dispatch } = useContext(AuthContext);
    return [state, dispatch];
};

//add loader while sending otp
export const setLoading = (dispatch, isLoading) => dispatch({ type: SET_LOADING_BETWEEN_STEPS, payload: isLoading });

// Load User
export const loadUser = async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get('/v1/user/load');
        
        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: AUTH_ERROR,
            payload: ''
        })
    }
};

// Register User
export const register = async (dispatch, formData) => {
};

// Send OTP
export const sendOtp = async (dispatch, formData) => {
    setLoading(dispatch, true)
    try {
        await axios.post('/v1/user/send-otp', formData);
        
        dispatch({
            type: OTP_SUCCESS
        });

    } catch (err) {
        dispatch({
            type: OTP_FAIL,
            payload: err.response ? (err.response.data.title ?? 'ארעה שגיאה') : 'ארעה שגיאה'
        });
    }
    setLoading(dispatch, false)
};

export const resendCode = async (dispatch) => {
   
    dispatch({
        type: RETURN_STEP_1
    });
};

// Login User
export const login = async (dispatch, formData) => {
    setLoading(dispatch, true)

    try {
        const res = await axios.post('/v1/user/login', formData);
        
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    
        loadUser(dispatch);
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL,
            payload: err.response?.data?.title ?? 'ארעה שגיאה'
        });
    }
    setLoading(dispatch, false)

}

// Switch User
export const switchUser = async (dispatch, formData) => {
    
    try {
        const res = await axios.post('/v1/user/switch', formData);
        dispatch({
            type: SWITCH_SUCCESS,
            payload: res.data
        });
    
    } catch (err) {
        dispatch({
            type: SWITCH_FAIL,
            payload: err.response.data.title ?? 'ארעה שגיאה'
        });
    }
}

// Logout
export const logout = (dispatch) => {
    dispatch({ type: LOGOUT });
};

export const updateNotNoCreate = (dispatch) => dispatch({ type: UPDATE_NOT_NOW_CREATE });

// Clear Errors
export const clearErrors = (dispatch) => dispatch({ type: CLEAR_ERRORS });
export const clearSuccess = (dispatch) => dispatch({ type: CLEAR_SUCCESS  });

const AuthState = (props) => {
    const initialState = {
        token: localStorage.getItem('token'),
        isAuthenticated: null,
        isOtpSent: false,
        loading: true,
        user: null,
        switchedNow: false,
        validate: true,
        loadingBetweenSteps: false,
        error: '',
        success: ''
    };

    const [state, dispatch] = useReducer(authReducer, initialState);
    
    // set token on initial app loading
    setAuthToken(state.token);

    // load user on first run or refresh
    if (state.loading && !state.user) {
        loadUser(dispatch);
    }

    // 'watch' state.token and set headers and local storage on any change
    useEffect(() => {
        setAuthToken(state.token);
    }, [state.token]);

    return (
        <AuthContext.Provider value={{ state: state, dispatch }}>
        {props.children}
        </AuthContext.Provider>
    );
};

export default AuthState;