import React, {useState} from 'react';
import { useCustomers, sendMessage, createNote, removeCurrent } from '../../context/customers/CustomersState';

import {
    Grid, GridColumn
} from '@progress/kendo-react-grid';

const CustomerItem = ({ customer }) => {
    const [customersState, customersDispatch] = useCustomers();
    const { current, notes, orderNotes, orderStatuses } = customersState;

    const sendClick = e => {
        sendMessage(customersDispatch, current.id, document.getElementById('inputText').value);
        document.getElementById('inputText').value = '';
    };

    const noteClick = e => {
        createNote(customersDispatch, current.id, {'text': document.getElementById('inputText').value});  
        document.getElementById('inputText').value = '';
    }

    // customize the status column in the orders side table (set background color)
    const cellWithBackGround = (props) => {
        
        const statusObj = orderStatuses.filter((status) => +props.dataItem.status === status.id)[0];
        const statusColor = statusObj.color;
        const statusName = statusObj.name; 
        
        const style = {
            backgroundColor: statusColor,
            height: '80%',
            width: '100%',
            maxWidth: '130px',
            textAlign: 'center',
            color: '#fff',
            fontSize: '14px',
            borderRadius: '5px',
            padding: '3px 15px',
        };
        
        return (
          <td>
              <div style={style}>
                {statusName}
              </div>
          </td>
        );
      };

    const [tab, setTab] = useState('notes');

    return (current ?
        <div className='card cardCustomer'>
            <div className='close' onClick={ ()=>removeCurrent(customersDispatch)}><i className="fas fa-times"></i></div>
            <div className='card_wrapper'>
                <div className='card_tabs'>
                    <button className={tab === 'notes' ? 'card_tabsBtn active' : 'card_tabsBtn inactive'} onClick={() => {setTab('notes');}}>
                        <svg id="Group_192" data-name="Group 192" width="22.864" height="20.398" viewBox="0 0 22.864 20.398">                       
                                <path id="Path_72" data-name="Path 72" d="M2.974,12.444H1V1H16.8V3.081" transform="translate(1.215 1.158)" fill="none" stroke="#bad564" strokeWidth="2"/>
                                <path id="Path_73" data-name="Path 73" d="M7,7v9.7h7.5v3.086L17.581,16.7h3.527V7Z" transform="translate(-0.452 -0.452)" fill="none" stroke="#bad564" strokeWidth="2"/>      
                        </svg>
                        עדכונים
                    </button>
                    <button className={tab === 'orders' ? 'card_tabsBtn active' : 'card_tabsBtn inactive'} onClick={() => {setTab('orders');}}>
                    <svg width="31" height="37" viewBox="0 0 31 37">
                        <g id="Group_245" data-name="Group 245" transform="translate(8 8)">
                            <g id="Group_242" data-name="Group 242">
                            <g id="Group_241" data-name="Group 241" clip-path="url(#clip-path)">
                                <path id="Path_76" data-name="Path 76" d="M4.07,7H15.325V25.066H1V7H4.07" transform="translate(-0.772 -3.487)" fill="none" stroke="#bad564" stroke-width="2"/>
                            </g>
                            </g>
                            <g id="Group_244" data-name="Group 244">
                            <g id="Group_243" data-name="Group 243" clip-path="url(#clip-path-2)">
                                <line id="Line_21" data-name="Line 21" x2="9" transform="translate(3 8)" fill="none" stroke="#bad564" stroke-width="1.5"/>
                                <line id="Line_22" data-name="Line 22" x2="9" transform="translate(3 11)" fill="none" stroke="#bad564" stroke-width="1.5"/>
                                <line id="Line_23" data-name="Line 23" x2="9" transform="translate(3 14)" fill="none" stroke="#bad564" stroke-width="1.5"/>
                                <line id="Line_24" data-name="Line 24" x2="9" transform="translate(3 17)" fill="none" stroke="#bad564" stroke-width="1.5"/>
                            </g>
                            </g>
                        </g>
                    </svg>
                        הזמנות
                    </button>
                </div>
                {tab === 'notes' &&
                    <div className='card_top'>
                        <div className='card_text'>
                            <textarea id='inputText' type="text" name="name" placeholder='כתוב כאן...'></textarea>
                        </div>
                        <div className='card_btns' id='divBtnPopup'>
                            <button className='btnPopup btnSendSMS' id='btnSendSMS' onClick={sendClick}><i className="fas fa-sms"></i>שליחת SMS</button>
                            <button className='btnPopup btnAddMessage' id='btnAddMessage' onClick={noteClick}><i className="fas fa-comment-dots"></i>הוספת הודעה</button>
                        </div>
                    </div>
                }
                <div className= {tab === 'notes' ? 'card_content notes' : 'card_content orders'}  >
                    {tab === 'notes' &&
                        <div className='card_comments'>
                            {notes.map((note) => {
                                return <div key={note.id} className='mess_line'>
                                        <div className="note">
                                            <div className="avatar" style={{backgroundColor: note.color, color: note.textColor}}>{note.name}</div>
                                            <div className='mess_text' dangerouslySetInnerHTML={{__html: note.text}} ></div>
                                        </div>
                                        <div className='mess_date'>{note.date}</div>
                                    </div>; 

                            })}
                        </div>
                    }
                    {tab === 'orders' && 
                        <div className='card_comments'>
                            

                            <div className='divWarpGrid'>
                    <div className='divWarpGrid_wrapper' dir='rtl'>
                        <Grid        
                            data={orderNotes ?? []}
                            key='customerOrders'
                        >
                            <GridColumn key='idOrder' field='id' title='ID' width={100}/>
                            <GridColumn key='orderCreated' field='delivery_date' title='תאריך' width={110}/>
                            <GridColumn key='orderStatus' field='column' title='סטטוס' cell={cellWithBackGround} width={100}/>
                        </Grid>
                    </div>
                </div>

                        
                        </div>
                    }
                </div>
            </div>
        </div> : false

    )
}


export default CustomerItem
