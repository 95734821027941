import React from 'react';
import { useParams } from "react-router-dom";
import Quantity from './Quantity';
import { useOrders, updateProduct, setEnlarged} from '../../context/orders/OrdersState';
import {
    isBrowser,
    isMobile,
  } from "react-device-detect";

const CartItem = ({item, showPrices}) => {
    const { description, price_includes_vat, image, unit, quantity, remarks, is_missing } = item;
    const ordersDispatch = useOrders()[1];

    const params = useParams();
    const orderId = params.id ?? null;

    // changing remarks input
    const saveRemarks = e => {  
        item.remarks = e.target.value;
        updateProduct(ordersDispatch, orderId, item, true);
    }   

    const enlargeImg = () => {
        setEnlarged(ordersDispatch, item);
    }

    return (item &&        
        <div className={is_missing == 1? 'cartItem disabled' : 'cartItem'}>
            <div className='cart_item_line line1'>
                <div className='cart_item_line_R'>
                    <div className='cart_product'>
                        <div className='cart-desc'>

                            {isMobile && image &&
                                <div className='cart-pic'>
                                    <img onClick={enlargeImg} src={process.env.PUBLIC_URL + `/images` + image} alt={""}/>
                                </div>
                            }
                            <div className={is_missing == 1? "main_info missing" : "main_info"}>{description}</div>
                            {orderId !== null &&
                                <div className={is_missing == 1? "small_info missing" : "small_info"}>{unit}</div>
                            }
                        </div>
                    </div>
                </div>
                <div className='cart_item_line_L'>
                    <div className='cart-quantity'>
                        {
                        // orderId is defined only if we are in the Order page (and not in the tab of orders)
                        orderId !== null ?
                            <Quantity  product={item} orderId={orderId}/>
                        :
                            quantity + ' ' + unit
                        }                        
                    </div>
                </div>
            </div>
            <div className='cart_item_line line2'>
                <div className='cart_item_line_R'>                    
                    <div className='cart-remarks'>
                        {
                        // orderId is defined only if we are in the Order page (and not in the tab of orders)
                        orderId !== null ?
                            <input defaultValue={remarks ?? ''} onBlur={saveRemarks}  placeholder='&#xf303;   הוסיפו הערה' type='text' />
                        :
                            remarks
                        }
                    </div>                                        
                </div>
                {showPrices &&
                    <div className='cart_item_line_L'>
                        <div className='cart-price'>
                            <div className={is_missing == 1? "main_info missing" : "main_info"}>{price_includes_vat} <i className="fas fa-shekel-sign"></i></div>
                        </div>
                    </div>
                }
                
                
            </div>
        </div>    
    )
}

export default CartItem
