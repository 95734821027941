import React from 'react';
import { useOrders, toggleDepartment, searchByFamily, setOrderSearchFamily } from '../../context/orders/OrdersState';

const Department = ({allDepartments, department, families}) => {
    const [ordersState, ordersDispatch] = useOrders();
    const { currentOrder, orderSearchFamily } = ordersState;
    const toggleDep = e => { 
        if (department.open === 1) {
            // MQZT-81: asked to do nothing
            //department.open = 0;
            //toggleDepartment(ordersDispatch, department);  
        } else {
            // it's closed - close all and open this one    
            allDepartments.map((department) => {
                if (department.open === 1) {
                    department.open = 0;
                    toggleDepartment(ordersDispatch, department);   
                }
                return false;
            })
            department.open = 1;
            toggleDepartment(ordersDispatch, department);            
        }
        
    };

    const familyClick = e => {
        if(orderSearchFamily !== e.target.id){
            searchByFamily(ordersDispatch, currentOrder.fields.id, e.target.id);    
            setOrderSearchFamily(ordersDispatch, e.target.id)
        }
    }


    return (department && 
        <div className={"department " + (department.open === 1 ? 'department-open' : '')}>
            <div className='department-title' onClick={toggleDep}>
                <div className='department-title-image'><img src={process.env.PUBLIC_URL + `/images/${department.name}.svg`} alt="departmentLogo"/> </div>
                <div className='department-title-content'>{department.name}</div>
            </div>
            
            {department.open === 1 &&
                <ul className='family'>
                    {families.map(family => (
                        <li onClick={familyClick} style={{fontWeight: Number(family.id) === Number(orderSearchFamily) ? 'bold' : 300}} key={family.id} id={family.id}>
                            {family.name}
                        </li>
                    ))}
                </ul>
            }
        </div>         
    )
}

export default Department
