import React, { Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
// import Login from '../auth/Login';
import { useAuth, logout, switchUser } from "../../context/auth/AuthState";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  isBrowser,
  isMobile,
} from "react-device-detect";
import Logo from "../../images/logo-ada.png";
import "./layout.scss";

const NavbarOrLogin = () => {
    const [authState, authDispatch] = useAuth();
    // loading
    const { isAuthenticated, user, switchedNow } = authState;

  const defaultItem = {
    customerName: user?.customer,
    title: user?.customer,
  };

  const navigate = useNavigate();
  const onLogout = () => {
    logout(authDispatch);
  };

  const onClick = (e) => {
    switchUser(authDispatch, { customerId: e.value.customerId });
  };

  // after switching user - go to orders and reload
  useEffect(() => {
    if (switchedNow) {
      navigate("/orders/");
      window.location.reload();
    }
  }, [switchedNow, navigate]);

  const itemRender = (li, itemProps) => {
    const index = itemProps.index;
    
    const itemChildren = (
      <span title={itemProps.dataItem.customerName}>
     {itemProps.dataItem.customerName}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const menuItems = user ? user.menuItems : [];

  const authLinks = (
    <Fragment>
      <div className="account_wrapper d-flex">
        {(user && user.switchUsers.length>0 && isBrowser) && (
          <Tooltip parentTitle={true} anchorElement="target" position="right">
            <DropDownList
              data={user.switchUsers}
              itemRender={itemRender}
              onChange={onClick}
              title={defaultItem.customerName}
              textField="customerName"
              defaultItem={defaultItem}
            />
          </Tooltip>
        )}
        <ul className="account">
          <li className="user">
            <div className="avatar">
              {user &&
                (user.first_name?.charAt(0) ?? "") +
                  (user.last_name?.charAt(0) ?? "")}
            </div>
            {isBrowser && <span >
              {user && (user.first_name ?? "") + " " + (user.last_name ?? "")}
            </span>}
            {isBrowser &&<i className="fas fa-chevron-down"></i>}
            <ul className="account_dropdown">
              <li className="account_log">
                <a onClick={onLogout} href="/login">
                  <i className="fas fa-sign-out-alt"></i>
                  <span className="hide-sm">התנתק</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );

  const menu = (
    <div className={"navbar " + (isMobile && "navbar-mobile")}>
      <div className="navbar_R">
        <div
          className="logo"
          onClick={() => {
            navigate("/orders/");
          }}
        >
          <img src={Logo} alt="logo"></img>
        </div>
        {isBrowser && (
          <div className="nav_links">
            <ul>
              {menuItems?.map((item, index) => (
                <li key={index}>
                  <NavLink
                    className={
                      window.location.pathname === "/" &&
                      item.description === "Orders"
                        ? "emptyPath"
                        : "fullPath"
                    }
                    to={{ pathname: item.url, fromProps: { from: "navBar" } }}
                    activeclassname="active"
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="navbar_L">{authLinks}</div>
      {/* <ul>
            <div className='li-div'>
                <li>
                    <Logo />
                </li>
                <div className='right'>
                    {menuItems?.map((item, index) => (
                        <li key={index} >
                            <Link to={item.url}>{item.title}</Link>
                        </li>
                    ))}
                </div>
            </div>
            {authLinks}
        </ul> */}
    </div>
  );

  if (isAuthenticated) {
    return menu;
  } else {
    return "";
  }
};

export default NavbarOrLogin;
