import {
    GET_PRODUCTS,
    PRODUCTS_LOOKUP,
    SET_QUENTITY,
    TOGGLE_DAY,
    DELETE_LINES,
    UPDATE_LINES,
    PRODUCT_ERROR,
    CLEAR_CHANGES,
    CLEAR_SUCCESS,
    SET_LOADING,
    CLEAR_ERRORS
} from '../types';

const productsReducer = (state, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
        }       
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload.rows,
                copy: JSON.parse(JSON.stringify(action.payload.rows)),
                count: action.payload.count,
                error: null
        }
        case CLEAR_CHANGES:
            const newProducts = state.copy;
            return {
                ...state,
                products: newProducts,
                copy: JSON.parse(JSON.stringify(newProducts)),
        }
        case PRODUCTS_LOOKUP:
            return {
                ...state,
                columns: action.payload.gridSettings.orders.columns,
                labels: action.payload.labels,
                days: action.payload.extraData.days
        }
        case TOGGLE_DAY:
            return {
                ...state,
                days: state.days.map((day) =>
                    day.date === action.payload.date ? action.payload : day
                ),
                copy: state.copy
            }    
        case DELETE_LINES:
            return {
                ...state,
                products: action.payload.rows,
                copy: JSON.parse(JSON.stringify(action.payload.rows)),
                count: action.payload.count,
                success: action.length + " שורות נמחקו בהצלחה"
            }      
        case UPDATE_LINES:
            return {
                ...state,
                products: action.payload.rows,
                copy: JSON.parse(JSON.stringify(action.payload.rows)),
                count: action.payload.count,
                success: "העדכון בוצע בהצלחה"
            }   
        case SET_QUENTITY:
            return {
                ...state,
                products: state.products.map((product) =>
                    product.order_line_id === action.payload.order_line_id ? action.payload : product
                )
            } 
        case PRODUCT_ERROR:
            return {
                ...state,
                error: ''
            };
        case CLEAR_SUCCESS:
            return {
                ...state,
                success: ''
        }        
        case CLEAR_ERRORS:
            return {
                ...state,
                error: ''
        }
        default:
            return state;
    }
}
export default productsReducer;