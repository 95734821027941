import { useEffect, useState, useRef } from "react";
import { Grid, GridColumn, getSelectedState, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import popupStyles from "../../css/custom-popup.module.css"
import PropTypes from "prop-types";
import {
  addForbiddenDate,
  deleteForbiddenDate,
  useOrders
} from "../../context/orders/OrdersState";

import { Loader } from "@progress/kendo-react-indicators";
import {
  IntlProvider,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";

const DATA_ITEM_KEY = "id";

const ForbiddenDatesPopup = (props) => {
  const [show, setShow] = useState(false);
  const [showCal, setShowCal] =useState(false);
  const [ordersState, ordersDispatch] = useOrders();
  const { loading, forbiddenDates } = ordersState;
  const [editID, setEditID] = useState(null);

  const closeHandler = (e) => {
    if(e.target.className === popupStyles.overlay || e.target.className === popupStyles.close){
      setShow(false);
      setShowCal(false);
      props.onClose(false);
    }
  };
  
  useEffect(() => {
    setShow(props.show);
  }, [props.show]);
  
  const _grid = useRef();
  const myFormatter = new Intl.DateTimeFormat("en-UK", {day: "2-digit", month: "2-digit", year: "numeric"});
  var lang = "he";

  const deleteForbidden = (forbidden_date) => {
    //console.log(forbidden_date);
    deleteForbiddenDate(ordersDispatch, forbidden_date);
  }

  const deliveryCell = (props) => {
    const style = {
      cursor: props.dataItem.showId && !loading ? "pointer" : "",
    };
    
    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        key={props.dataItem['id']}
        style={style}
      >    
        {props.dataItem[props.field] !== '' ? myFormatter.format(new Date(props.dataItem[props.field])) :
                                              <button id="btnNewDate" onClick={(e) => setShowCal(true)} className="btn btn-pick-date">בחר תאריך</button>}
        
        {props.dataItem[props.field] !== '' &&
          <i onClick={(e) => deleteForbidden(props.dataItem[props.field])} className="clear-forbidden fas fa-times"></i>
        }                             
      </td>
    )
  };

  const closeEdit = (event) => {
    if (event.target === event.currentTarget) {
      setEditID(null);
    }
  };

  const addRecord = () => {
    if (editID) {
      return true;
    }
    addForbiddenDate(ordersDispatch, '');
    setEditID(forbiddenDates.length);
  };

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className={popupStyles.overlay}
      onClick={closeHandler}
    >
      
      <div className={[popupStyles.popup + ' forbidden_popup']}>
        <span className={popupStyles.close} onClick={closeHandler}>
          &times;
        </span>
        <div className='divWarpGrid'>
        <div className={loading ? 'loading divWarpGrid_wrapper' : 'divWarpGrid_wrapper'} dir='rtl'>
            {loading && <Loader type="infinite-spinner"/>}
            {showCal &&
        <LocalizationProvider language={lang}>
        <IntlProvider locale={lang}>
          <DatePicker
  
            format='yyyy-MM-dd'
           // defaultValue={new Date('today')}
            onChange={(date) => {
              setShowCal(false);
              setEditID(null);
              addForbiddenDate(ordersDispatch, myFormatter.format(date.value));
            }}
            style={{flex: '0 0 55%',
            maxWidth: '75%',
          position: 'absolute',
          letf: '300px'}}
            show={true}
            
          />
        </IntlProvider>
        </LocalizationProvider>
        }
                <Grid
                    data={forbiddenDates?.map((item) => ({
                        ...item,
                    }))}
                    dataItemKey={DATA_ITEM_KEY}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    skip={0}
                    take={20}
                  //  sort={true}
                    /*pageable={{
                        buttonCount: 10,
                        info: false,
                        type: "numeric",
                        previousNext: true,
                    }}*/
                    sortable={true}  
                    ref={_grid}
                >
                <GridNoRecords>     
                    <div>
                        <i className="fas fa-search"></i>   אין נתונים
                    </div>                      
                </GridNoRecords>    
                <GridToolbar>
                  <div onClick={closeEdit}>
                    <button
                      title="הוסף תאריך"
                      className="btn btn-add-date"
                      onClick={addRecord}
                    >
                      + הוסף תאריך
                    </button>
                  </div>
                </GridToolbar>    
                <GridColumn key={'id'} field={'name'} title={' '} cell={deliveryCell}/>
                                
                </Grid>
                        
            </div>
        </div>    
        <div className={popupStyles.content}>{props.children}</div>
      </div>
    </div>
  );
};

ForbiddenDatesPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
export default ForbiddenDatesPopup;