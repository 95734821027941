import React, { useState } from 'react';
import {
  useOrders,
  addError,
  uploadOrders,
} from '../../context/orders/OrdersState';
import { Loader } from "@progress/kendo-react-indicators";
// import exampleFile from "../../files/קובץ לדוגמא למספר הזמנות.xlsx"

const UploadOrdersFile = (props) => {
  // eslint-disable-next-line
  const [ordersState, ordersDispatch] = useOrders();
  const {loading} = ordersState;
  const [isFileLoading, setIsFileLoading] = useState(false);

  const onFileChange = (e) => {
    setIsFileLoading(true)
    if(e.target.files.length > 0) {
      var fileTypeArr = ["text/comma-separated-values", "text/csv", "application/csv", "application/vnd.ms-excel", 'xlsx', 'xls', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      if ( fileTypeArr.indexOf(e.target.files[0].type) < 0){
        addError(ordersDispatch,'נא להעלות קובץ מסוג csv או excel');
        setIsFileLoading(false)
      }else{
        // Create an object of formData
        const formData = new FormData();
        if (e.target.files[0]) {
            // Update the formData object
            formData.append('myFile', e.target.files[0], e.target.files[0].name);
            uploadOrders(ordersDispatch, formData, props.data.search, props.data.dates, props.data.days, props.data.lines, props.data.statuses).then(val =>{
              setIsFileLoading(val)
            })
        }
      }
    }
  };

  return (
    <div className='form-group fileUploadSection'>
      <div className={'uploadOrders btn input-group-btn' + (loading ? ' disabled' : '')} style={{backgroundColor : props.color}}>
        {
          //  style={{ opacity: loading ? 0.7 : 1 }}
          <span className='fileUpload'>
                  
            {isFileLoading ?  <Loader type="pulsing"/> :
              <span className='upl' id='upload'>          
                  <i className="fas fa-file-upload"></i> העלאת אקסל למספר לקוחות       
              </span>
            }
              <input
              type='file'
              className='upload up'
              id='up'
              onChange={onFileChange}
              disabled= {isFileLoading}
              accept=".xlsx, .xls, .csv, , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
          </span>
        }
      </div>    
      {/* <a href={exampleFile} download>
        קובץ לדוגמא
      </a> */}
    </div>
  );
};

export default UploadOrdersFile;
