import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { cloneElement } from "react";
import { useOrders } from "../../context/orders/OrdersState";
import OrderItemMobile from "./OrderItemMobile";
import { useAuth, logout, switchUser } from "../../context/auth/AuthState";

const OrdersMobile = (props) => {
  const [ordersState, ordersDispatch] = useOrders();
  const { statuses, statusChange ,openCustomers} = ordersState;
  const [authState, authDispatch] = useAuth();
  // loading
  const { user } = authState;
  const defaultItem = {
    customerName: user?.customer,
    title: user?.customer,
  };
  const onClick = (e) => {
    switchUser(authDispatch, { customerId: e.value.customerId });
  };
  const itemRender = (li, itemProps) => {
    const index = itemProps.index;
    const itemChildren = (
      <span title={itemProps.dataItem.customerName}>
      {itemProps.dataItem.customerName}
      </span>
    );
    return cloneElement(li, li.props, itemChildren);
  };
  return (
    <div className="orders-container" >
        {/* {props.calanderCreate} */}
        {/* <div className="search_wrapper">
        {openCustomers && props.customersCreate}
             {props.yearsCreate}</div> */}
             
             {(user && user.switchUsers.length>0) && 
          <Tooltip parentTitle={true} anchorElement="target" position="right">
            <DropDownList
              data={user.switchUsers}
              itemRender={itemRender}
              onChange={onClick}
              title={defaultItem.customerName}
              textField="customerName"
              defaultItem={defaultItem}
            />
          </Tooltip>
        }           
             
        {!props.orders.length && <div className="noResault">לא נמצאו תוצאות</div>}
        {props.orders.map(order=>(
            <OrderItemMobile onChangeOrderStatus={props.onChangeOrderStatus} key={order.id} order={order}/>
        ))}
      
      </div>
  );
};

export default OrdersMobile;
