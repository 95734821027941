import React, { useEffect, useState } from "react";
import { BottomNavigation } from "@progress/kendo-react-layout";
import { useAuth, logout, switchUser } from "../../context/auth/AuthState";
import { useNavigate } from "react-router-dom";
import { createOrder, getLookup, setShowCustomersPopup, updateNotNoCreate, useOrders } from "../../context/orders/OrdersState";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
const items = [
  {
    text: "ההזמנות שלי",
    id: 1,
    icon: "track-changes-accept",
    route: "/orders",
    selected: true,
  },
  {
    text: "הזמנה חדשה",
    id: 2,
    icon: "plus-outline",
    route: "/cart",
  },
  {
    text: "התנתקות",
    id: 3,
    icon: "logout",
    route: "/login",
  },
];
const BottomNav = (props) => {
  const navigate = useNavigate();
  const [ordersState, ordersDispatch] = useOrders();
  const { openCustomers,customerId,createdNow,currentOrder, existingOrder} = ordersState;
  const [askExisting, setAskExisting] = useState(false);
  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user, switchedNow } = authState;
  const onSelect = (ev) => {

    if (ev.itemTarget.route === "/login") {
      onLogout();
    }
    if (ev.itemTarget.route === "/cart") {
      if (!openCustomers) {
        create();
      }
      else{
        console.log('here')
        setShowCustomersPopup(ordersDispatch,true)
      }
    }
    else{
        navigate(ev.itemTarget.route);
    }
  
    // selected = ev.itemTarget.id;
  };
   // getting lookup at the first load
   useEffect(() => {
    if (createdNow && currentOrder !== null) {
      navigate('/cart/' + currentOrder['id']);
      updateNotNoCreate(ordersDispatch);
    }
    getLookup(ordersDispatch);
  }, [ordersDispatch, createdNow, currentOrder]);

  const create = (e) => {
    if (existingOrder && !askExisting) {
      setAskExisting(true);
    } else {
      setAskExisting(false);
      createOrder(ordersDispatch, customerId);
    }
  };
  const onLogout = () => {
    logout(authDispatch);
  };
  const setSelected = (pathName) => {
    let currentPath = items.find((item) => pathName.includes(item.route));
    if (currentPath && currentPath.text) {
      return currentPath.text;
    }
    else return items[0].text
  };
  let selected = setSelected(window.location.pathname);
  const bottomNav = (
    <div>
      <BottomNavigation
        positionMode={"fixed"}
        items={items.map((item) => ({
          ...item,
          selected: item.text === selected,
        }))}
        onSelect={onSelect}
      />
      {askExisting && (
        <Dialog
          title={"קיימת עבורך הזמנה במערכת"}
          onClose={() => setAskExisting(false)}
          className="pop-up"
        >
          <p>מה ברצונך לעשות?</p>
          <DialogActionsBar>
            <button
              className="btn new"
              onClick={() => {create()}
                 }
            >
              הזמנה חדשה
            </button>
            <button className="btn update" onClick={() => {setAskExisting(false); navigate("/cart/" + existingOrder)}}>
              עדכון הזמנה
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
    
  );
  if (isAuthenticated && selected=='ההזמנות שלי') {
    return bottomNav;
  } else {
    return "";
  }
};

export default BottomNav;
