import React, { useState, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import AlertContext from '../../context/alert/alertContext';
import { useAuth, clearErrors, login, sendOtp, clearSuccess, resendCode } from '../../context/auth/AuthState';
// import IconButton from '@mui/material/IconButton';
// import Spinner from '../layout/Spinner';
import './Login.css';
import Logo from '../../images/logo-ada.png';
import LogoNoText from '../../images/logo-no-text.png';
import { Loader } from "@progress/kendo-react-indicators";
import ReCAPTCHA from 'react-google-recaptcha'

const Login = (props) => {
    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;
    const [authState, authDispatch] = useAuth();
    const { error,success, isAuthenticated, isOtpSent, validate, loadingBetweenSteps } = authState;

    useEffect(() => {
        if (error !== '') {
            setAlert(error, 'danger');
            clearErrors(authDispatch);
        }
    // eslint-disable-next-line 
    }, [error, authDispatch]);

    useEffect(() => {
        if (success !== '' && success !== null) {
          setAlert(success, 'success');
          clearSuccess(authDispatch);
        }
    // eslint-disable-next-line 
    }, [success, authDispatch]);

    const [user, setUser] = useState({
        phone: '',
        recaptcha: '',
        password: ''
    });

    const { phone, recaptcha, password } = user;

    const onChange = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    function onChangeCaptcha(value) {
        setUser({ ...user, recaptcha: value });
      }
    const onSubmitLogin = (e) => {
        e.preventDefault();
        login(authDispatch, { phone, password });
    };

    const onSubmitOtp = (e, validateT) => {
        e.preventDefault();
        if(validateT)
            sendOtp(authDispatch, {phone, recaptcha, validate});
        else{
            resendCode(authDispatch)
        }
    };

    if (isAuthenticated){
        return <Navigate to='/orders' />;
    }

    return <>
        {
            isOtpSent ? 
            <div className='form-container-1 code' style={{backgroundImage: `url(${LogoNoText})`}}>
                <div className='cardLogin'>
                    <div className='logoDiv'>
                        <img src={Logo} alt="logo"/>
                    </div>
                    <div className='title'>
                        <span className="text-primary-1">התחברות</span>
                   </div>

                    <form onSubmit={onSubmitLogin}>
                        <input type="hidden" name="phone" value={phone} />
                        <div className="form-group">
                            <input className='input_log' placeholder='הכנס/י את הקוד שקיבלת' type="tel" name="password" value={password} onChange={onChange} />
                        </div>
                        <div className='sendAgain' onClick={(e) => {onSubmitOtp(e, false);}}>
                            לא קיבלת קוד? שליחה חוזרת</div>
                        <button className="btnCode" type='submit' disabled={loadingBetweenSteps}>
                            <div className='btnLogin'>
                            {loadingBetweenSteps ?
                                <Loader type="pulsing"/> :
                               "כניסה"
                            }
                            </div>
                        </button>
                    </form>
                  
                </div>

            </div> : 
            <div className='form-container-1' style={{backgroundImage: `url(${LogoNoText})`}}>
                <div className='cardLogin'>
                    <div className='logoDiv'>
                    <img src={Logo} alt="logo"/>
                    </div>
                    <div className='title'>               
                        התחברות
                    </div>
                    <form onSubmit={(e) => {onSubmitOtp(e, true);}}>
                        <div className="form-group-1">
                            <input className='textInput input_log' placeholder="מס' טלפון" type="tel" name="phone" value={phone} onChange={onChange} />
                        </div>
                        <div className={validate ? "Captcha" : "Captach noCheck"} >
                            <ReCAPTCHA
                                sitekey="6LdfaE0eAAAAAIKky_KRJn6VBbelpU8UJqTlXuj0"
                                onChange={onChangeCaptcha}
                                hl='he'
                            />
                        </div>
                        <button type='submit' className="btnCode" disabled={loadingBetweenSteps}>
                            <div className='sendCode'>
                            {loadingBetweenSteps ?
                                <Loader type="pulsing"/> :
                                "שליחת קוד"
                            }                           
                            </div>
                        </button>
                    </form>
                </div>
            </div>

        }
    </>
}

export default Login
