/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  Fragment,
} from "react";
import {
  Grid,
  GridColumn,
  getSelectedState,
  GRID_COL_INDEX_ATTRIBUTE,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DateRangePicker, DatePicker } from "@progress/kendo-react-dateinputs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import InfiniteScroll from "react-infinite-scroller";

import {
  IntlProvider,
  LocalizationProvider,
  load,
  loadMessages,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/he/numbers.json";
import caGregorian from "cldr-dates-full/main/he/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/he/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/he/timeZoneNames.json";
import Slider from "react-slick";
import { getter } from "@progress/kendo-react-common";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";
import esMessages from "../../json/he.json";
import CustomersPopup from "./CustomersPopup";
import ForbiddenDatesPopup from "./ForbiddenDatesPopup";
import { useAuth } from "../../context/auth/AuthState";
import OrderItem from "./OrderItem";
import { Loader } from "@progress/kendo-react-indicators";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import AlertContext from "../../context/alert/alertContext";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import UploadOrdersFile from "./UploadOrdersFile";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {
  useOrders,
  getOrders,
  setCurrent,
  createOrder,
  setTodayWasClicked,
  getLookup,
  toggleDay,
  toggleLine,
  toggleStatus,
  clearErrors,
  clearSuccess,
  updateNotNoCreate,
  duplicateOrder,
  hideSuccessPopUp,
  updateStatus,
  updateDelivery,
  updateMultiple,
  setShowCustomersPopup,
  setShowForbiddenDatesPopup,
  emptyOrders,
  removeCurrent,
  clearUploadMessages,
  setSearch,
  setSort
} from "../../context/orders/OrdersState";
import OrdersMobile from "./OrdersMobile";
import { col, i } from "react-dom-factories";
import { relativeContextElement } from "@progress/kendo-react-data-tools";
import { DialogActions, DialogContent } from "@mui/material";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(esMessages, "he");
const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const myFormatter = new Intl.DateTimeFormat("fr-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const Orders = () => {
  const [ordersState, ordersDispatch] = useOrders();
  const {
    orders,
    allOrdersForExcel,
    currentOrder,
    createdNow,
    submittedNow,
    updatedNow,
    statusNow,
    columns,
    statuses,
    labels,
    lines,
    successPopUp,
    lastSubmitted,
    days,
    years,
    count,
    actions,
    error,
    customerId,
    openCustomers,
    statusChange,
    cancelledStatus,
    deletedStatus,
    sentStatus,
    success,
    loading,
    todayWasClicked,
    widths,
    showCustomersPopup,
    showForbiddenDatesPopup,
    uploadMessages,
    existingOrder,
    ordersSearch,
    ordersSort
  } = ordersState;
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [updateMultiplePopup, setShowUpdateMultiplePopup] = useState(false);
  const [valueChangeMultiple, setValueChangeMultiple] = useState();

  const [resched, setResched] = useState(null);
  const [askExisting, setAskExisting] = useState(false);
  const [mobileTake, setMobileTake] = useState(10);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [authState] = useAuth();
  const { user } = authState;
  const [scrollParentRef, setscrollParentRef] = useState();
  const [search, setLocalSearch] = useState("")

  const hasMore = () => {
    return orders.length < count ? true : false;
  };
  const popupCloseHandler = (e) => {
    setVisibility(e);

    setShowCustomersPopup(ordersDispatch, false);
    console.log("close", showCustomersPopup);
  };

  const popupCloseForbiddenHandler = (e) => {
    setShowForbiddenDatesPopup(ordersDispatch, false);
  };

  const clearUpload = (e) => {
    clearUploadMessages(ordersDispatch);
  };

  const onChangeStatus = (e) => {
    statuses.map((status) => (status.active = 0));
    e.value.active = 1;
    toggleStatus(ordersDispatch, e.value);
    console.log(statuses);
    
    setSearch(ordersDispatch, {...ordersSearch, statuses: statuses});
  };

  const [showCancelOrderPopUp, setshowCancelOrderPopUp] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState(0);

  const onChangeOrderStatus = async (id, status) => {
    //edit if there is few rows that checd
    if (checkedIds.length) {
      setShowUpdateMultiplePopup(true);
      setValueChangeMultiple({ status: status });
    }
    //edit one row
    else {
      if (status === cancelledStatus) {
        setshowCancelOrderPopUp(true);
        setOrderIdToCancel(id);
      } else {
        updateStatus(ordersDispatch, id, status);
      }
    }
  };

  const onChangeDelivery = async (delivery) => {
    if (checkedIds.length) {
      setShowUpdateMultiplePopup(true);
      setValueChangeMultiple({ delivery_date: delivery });
    } else {
      updateDelivery(ordersDispatch, resched.id, delivery);
    }
    setResched(null);
  };

  const confirmCancel = () => {
    updateStatus(ordersDispatch, orderIdToCancel, cancelledStatus);
    setshowCancelOrderPopUp(false);
  };

  const confirmUpdateMultiple = async () => {
    updateMultiple(ordersDispatch, checkedIds, valueChangeMultiple);
    setShowUpdateMultiplePopup(false);
    setValueChangeMultiple(null);
    setCheckedIds([]);
    
  };
  const [defaultValue, setDefaultValue] = useState({
    start: null,
    end: null,
  });


  //console.log(orders);
  var lang = "he";
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="prevArrow">
        <i className="fas fa-chevron-right" onClick={onClick} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="nextArrow">
        <i className="fas fa-chevron-left" onClick={onClick} />
      </div>
    );
  }

  // state is used for pagination and search
  const createState = (
    skip,
    take,
    search,
    dates,
    days,
    lines,
    statuses,
    years,
    page
  ) => {
    return {
      pageNumber: page ? page : skip / take + 1,
      skip: skip,
      take: take,
      search: search,
      dates: dates,
      days: days,
      lines: lines,
      statuses: statuses,
      years: years,
    };
  };

  const dayNames = ["ראשון", "שני", "שלישי", "רביעי", "חמישי"];

  const todayDate = `${("0" + new Date().getDate()).slice(-2)}.${(
    "0" +
    (new Date().getMonth() + 1)
  ).slice(-2)}.${new Date().getFullYear()}`.toString();
  const [state, setState] = useState(
    createState(
      0,
      20,
      "",
      [],
      // create today's object to select in days search on first init
      // [],
      isBrowser /*&& days.length*/
        ? !todayWasClicked &&
          new Date().getDay() !== 5 &&
          new Date().getDay() !== 6
          ? [
              {
                active: 1,
                date: todayDate,
                week: "this",
                day: dayNames[new Date().getDay()],
              },
            ]
          : []
        : [],
      [],
      [],
      ""
    )
  );

  // setting pagination
  const pageChange = (event) => {
   
    setSearch(ordersDispatch, {...ordersSearch, 
                               skip: event.page.skip, 
                               take: event.page.take, 
                               pageNumber: (event.page.skip / event.page.take + 1)});
  };

  // setting sort
  const sortClick = (event) => {
    setSort(ordersDispatch, event.sort);
  };

  // setting search
  const onChange = (e) => {
    
    setLocalSearch(e.target.value);
    console.log("here!!");
    // search only if search text is more than 3 letters long
    if (e.target.value.length >= 3 || e.target.value.length == 0) {
      if (isMobile) {
        emptyOrders(ordersDispatch);
      }

      
      setSearch(ordersDispatch, {...ordersSearch, skip: 0, search: e.target.value});
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
  };

  //setting the selected row in the grid
  const [selectedState, setSelectedState] = useState({});
  const onSelectionChange = useCallback(
    (event) => {
      if (!loading) {
        const newSelectedState = getSelectedState({
          event,
          selectedState: Object.keys(selectedState) && selectedState,
          dataItemKey: DATA_ITEM_KEY,
        });

        event.dataItems[event.startRowIndex].showId
          ? setSelectedState(newSelectedState)
          : setSelectedState({});

        event.dataItems[event.startRowIndex].showId &&
          (event.nativeEvent.target.classList[1] === "fa-info-circle" ||
            event.dataItems[event.startRowIndex].actions.indexOf("edit") < 0) &&
          setCurrent(ordersDispatch, event.dataItems[event.startRowIndex]);

        event.dataItems[event.startRowIndex].showId &&
          event.nativeEvent.target.classList[1] === "fa-clone" &&
          duplicateOrder(
            ordersDispatch,
            event.dataItems[event.startRowIndex].id
          );

        event.dataItems[event.startRowIndex].showId &&
          event.nativeEvent.target.classList[1] === "fa-calendar" &&
          setResched(event.dataItems[event.startRowIndex]);

        event.dataItems[event.startRowIndex].showId &&
          event.nativeEvent.target.classList[1] !== "fa-info-circle" &&
          event.nativeEvent.target.classList[1] !== "fa-clone" &&
          event.nativeEvent.target.classList[1] !== "fa-calendar" &&
          event.dataItems[event.startRowIndex].actions?.indexOf("edit") > -1 &&
          navigate("/orders/" + event.dataItems[event.startRowIndex].id);
      }
    },
    [selectedState]
  );

  // getting lookup at the first load
  useEffect(() => {
    if (createdNow && currentOrder !== null) {
      navigate("/orders/" + currentOrder["id"]);
      updateNotNoCreate(ordersDispatch);
    }

    if (!columns.length) {
      getLookup(ordersDispatch);
    }
    
    removeCurrent(ordersDispatch);
  }, [ordersDispatch, createdNow, currentOrder]);

  // after submitting new or updated order - show Sent status
  useEffect(() => {
    if (submittedNow || updatedNow) {
      let sortByModifiedDate = [
        {
          field: "modified",
          dir: "desc",
        },
      ];
      setSort(ordersDispatch, sortByModifiedDate);
      
    }
  }, [submittedNow, updatedNow]);
  useEffect(() => {
    const ids = orders.map((obj) => obj.id);
    const isSelect = ids.every((id) => checkedIds.includes(id));
    setIsSelectAll(isSelect);
  }, [orders, checkedIds]);

  useEffect(() => {
 
    // search only if statuses (for example) are loaded
    if (statuses.length) {
        console.log(ordersSearch);
        
        console.log("getorderssssssssss");
        
          getOrders(
            ordersDispatch,
            ordersSearch.pageNumber,
            ordersSearch.search,
            ordersSort[0],
            ordersSearch.dates,
            ordersSearch.days,
            ordersSearch.lines,
            ordersSearch.statuses,
            false,
            mobileTake,
            ordersSearch.years
          );
        }
    }, [
      /*ordersDispatch,*/
      ordersSearch,
      ordersSort,
      //state.pageNumber,
      //state.search,
      //sort,
      //state.dates,
      //state.days,
      //state.lines,
      //state.statuses,
      statusNow,
      //state.years,
      updatedNow
    ]
  );

  // if UnAuthorized - refresh page
  useEffect(() => {
    if (
      typeof error !== "undefined" &&
      error !== null &&
      typeof error.data !== "undefined" &&
      error.data.status === 401
    ) {
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    if (error !== "" && error !== null) {
      setAlert(error, "danger");
      clearErrors(ordersDispatch);
    }
  }, [error, ordersDispatch]);

  useEffect(() => {
    if (success !== "" && success !== null) {
      setAlert(success, "success");
      clearSuccess(ordersDispatch);
    }
  }, [success, ordersDispatch]);

  const create = (e) => {
    if (existingOrder && !askExisting) {
      setAskExisting(true);
    } else {
      setAskExisting(false);
      createOrder(ordersDispatch, customerId);
    }
  };
  const _export = useRef(null);
  const _grid = useRef();

  const [isLoadinExcel, setIsLoadingExcel] = useState(false);
  
  const exportToExcel = () => {
    setIsLoadingExcel(true);
    // getting all orders to export to excel
    getOrders(
      ordersDispatch,
      null,
      ordersSearch.search,
      ordersSort[0],
      ordersSearch.dates,
      ordersSearch.days,
      ordersSearch.lines,
      ordersSearch.statuses,
      true,
      mobileTake,
      ordersSearch.years
    ).then((res) => {
      if (_export.current !== null && res) {
        _export.current.save(res.rows, _grid.current.columns);
      }
      setIsLoadingExcel(false);
    });
  };

  const searchByDay = (day) => {
    if (day.date === todayDate && !todayWasClicked) {
      day.active = 0;
      toggleDay(ordersDispatch, day);
      setTodayWasClicked(ordersDispatch);
    } else {
      if (day.active === 1) {
        day.active = 0;
        toggleDay(ordersDispatch, day);
      } else {
        day.active = 1;
        toggleDay(ordersDispatch, day);
      }
    }

    // set all the others to be not active
    days.map((d) => {
      if (d.date !== day.date) {
        d.active = 0;

        // today has to be set as clicked
        if (d.date === todayDate && !todayWasClicked) {
          setTodayWasClicked(ordersDispatch);
        }

        toggleDay(ordersDispatch, d);
      }
      return true;
    });

    console.log(ordersSearch);
    setSearch(ordersDispatch, {...ordersSearch, days: days, dates: []});
  };

  const searchByLines = (line) => {
    if (line.active === 1) {
      line.active = 0;
      toggleLine(ordersDispatch, line);
    } else {
      line.active = 1;
      toggleLine(ordersDispatch, line);
    }
    
    setSearch(ordersDispatch, {...ordersSearch, lines: lines});
  };

  const [showState, setShow] = useState(false);

  // search orders by range of dates
  const searchByRange = (e) => {
    const dates = e.target.value;
    // clear all the days
    setDefaultValue({ start: dates.start, end: dates.end });
    if (dates.start && dates.end) {
      ordersSearch.days.map((day) => (day.active = false));

      // search by range of dates
      setSearch(ordersDispatch, {...ordersSearch, dates: [dates.start?.toLocaleDateString(), dates.end?.toLocaleDateString()], days: []});

      if (showState) {
        setShow(!showState);
      } else {
        setShow(true);
      }
    }
  };
  const calanderCreate = (isEndUser) => {
    const styleT = {
      flex: "0 0 330px",
      maxWidth: "330px",
    };
    const styleF = {
      flex: "0 0 15%",
      maxWidth: "15%",
    };
    return (
      <LocalizationProvider language={lang}>
        <IntlProvider locale={lang}>
          <DateRangePicker
            format={{ skeleton: "MMMMdE" }}
            defaultValue={defaultValue}
            onChange={searchByRange}
            // onFocus={show}
            value={{ start: defaultValue.start, end: defaultValue.end }}
            style={isEndUser ? styleT : styleF}
            show={showState === {} ? true : showState}
          />
        </IntlProvider>
      </LocalizationProvider>
    );
  };

  const daysCreate = (isEndUser) => {
    return (
      <div className="days" style={{ margin: isEndUser ? "0 23px" : "0 13px" }}>
        <ul>
          {days && (
            <Slider {...settings}>
              {[...days].reverse().map((day) => (
                <li
                  key={day.date}
                  className={
                    day.date === todayDate && !todayWasClicked
                      ? day.active !== 1
                        ? "day-active"
                        : "day"
                      : day.active === 1
                      ? "day-active"
                      : "day"
                  }
                  onClick={() => {
                    searchByDay(day);
                  }}
                >
                  <span className="dayInWeek">{day.day}</span>
                  <span className="dateInMonth">
                    {" "}
                    {day.date.substring(0, 10).split(".")[0]}.
                    {day.date.substring(0, 10).split(".")[1]}
                  </span>
                </li>
              ))}
            </Slider>
          )}
        </ul>
      </div>
    );
  };
  const customersCreate = () => {
    return (
      <div className="searchNameId">
        <i className="fas fa-search"></i>
        <input
          className="nameIdInput nameId"
          type="text"
          placeholder="הקלידו שם עסק / ID"
          onChange={onChange}
          value={ordersSearch.search}
        />
      </div>
    );
  };
  const yearsCreate = () => {
    return (
      <div className="filterByYear">
        <DropDownList
          // value={
          //   defaultYearItem
          // }
          data={years}
          onChange={onChangeYear}
          textField="name"
          // placeholder={defaultYearItem.name}
          defaultItem={defaultYearItem}
        />
      </div>
    );
  };
  const onChangeYear = (event) => {
    let years = event.value.name;
    emptyOrders(ordersDispatch);
    
    setSearch(ordersDispatch, {ordersSearch, years: years});
  };
  const actionsCell = (props) => {
    const style = {
      cursor: props.dataItem.showId && !loading ? "pointer" : "",
      paddingTop: 0,
      paddingBottom: 0,
    };
    return (
      <td
        style={style}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
      >
        {props.dataItem.showId && (
          <button className="actions">
            <Tooltip anchorElement="target" position="top">
              <i title="פירוט הזמנה" className="fas fa-info-circle"></i>
            </Tooltip>
          </button>
        )}
        {props.dataItem.actions?.indexOf("duplicate") > -1 && (
          <button className="actions">
            <Tooltip anchorElement="target" position="top">
              <i className="far fa-clone" title="שכפול"></i>
            </Tooltip>
          </button>
        )}
        {props.dataItem.actions?.indexOf("edit") > -1 && (
          <button className="actions">
            <Tooltip anchorElement="target" position="top">
              <i className="far fa-edit" title="עריכה"></i>
            </Tooltip>
          </button>
        )}
      </td>
    );
  };

  const deliveryCell = (props) => {
    const style = {
      cursor: props.dataItem.showId && !loading ? "pointer" : "",
    };
    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        style={style}
      >
        {props.dataItem[props.field]}
        <span />
        {props.dataItem.actions?.indexOf("resched") > -1 && (
          <button className="actions">
            <Tooltip anchorElement="target" position="top">
              <i
                id={"cal_ " + props.dataItem.id}
                className="far fa-calendar "
                title="עדכן ת. משלוח"
              ></i>
            </Tooltip>
          </button>
        )}
        {resched && resched.id == props.dataItem.id && (
          <LocalizationProvider language={lang}>
            <IntlProvider locale={lang}>
              <DatePicker
                format="yyyy-MM-dd"
                defaultValue={
                  new Date(
                    resched.delivery.split("-")[2] +
                      "-" +
                      resched.delivery.split("-")[1] +
                      "-" +
                      resched.delivery.split("-")[0]
                  )
                }
                onChange={(date) => {
                  onChangeDelivery(myFormatter.format(new Date(date.value)));
                }}
                //value={{ start: defaultValue.start, end: defaultValue.end }}
                style={{
                  flex: "0 0 55%",
                  maxWidth: "75%",
                  position: "absolute",
                  letf: "300px",
                }}
                show={resched && resched.id == props.dataItem.id}
              />
            </IntlProvider>
          </LocalizationProvider>
        )}
      </td>
    );
  };

  const idCell = (props) => {
    const style = {
      cursor: props.dataItem.showId && !loading ? "pointer" : "",
    };
    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        style={style}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
      >
        <div className="d-flex idCell">
          {props.dataItem.showId ? props.dataItem[props.field] : ""}
          {props.dataItem.showId && (
            <Tooltip anchorElement="target" position="top">
              <i title="פירוט הזמנה" className="fas fa-info-circle"></i>
            </Tooltip>
          )}
        </div>
      </td>
    );
  };
  const selectAll = (props) => {
    return (
      <div className="orderSelectCell">
        <Checkbox
          checked={isSelectAll && checkedIds.length > 0}
          onChange={(e) => handleSelectAllChange(e)}
        />
      </div>
    );
  };
  const selectCell = (props) => {
    return (
      <td className="orderSelectCell">
        <Checkbox
          checked={checkedIds.includes(props.dataItem.id)}
          onChange={(e) => onSelectRow(props.dataItem.id, e)}
        />
      </td>
    );
  };
  const onSelectRow = (id, event) => {
    if (event.target.value === true) {
      setCheckedIds([...checkedIds, id]);
    } else {
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    }
  };
  const handleSelectAllChange = (event) => {
    // setShowUpdateMultiplePopup(true);
    if (event.target.value === true) {
      const allIds = orders.map((item) => item.id);
      const filteredIds = allIds.filter((id) => !checkedIds.includes(id));
      setCheckedIds([...checkedIds, ...filteredIds]);
    } else {
      setCheckedIds([]);
    }
    console.log(checkedIds);
  };

  const regularCell = (props) => {
    const style = {
      cursor: props.dataItem.showId && !loading ? "pointer" : "",
    };
    return (
      <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        style={style}
      >
        {props.dataItem[props.field]}
      </td>
    );
  };

  // customize the status column (set background color)
  const cellWithBackGround = (props) => {
    const statusObj = statuses.filter(
      (status) => +props.dataItem.status === status.id
    )[0];

    const statusColor = statusObj.color;
    const DefaultName = statusObj;
    const style = {
      backgroundColor: statusColor,
      height: "28px",
      width: "100%",
      maxWidth: "130px",
      textAlign: "center",
      color: "#fff",
      fontSize: "14px",
      borderRadius: "5px",
      padding: statusChange[statusObj.id] ? "0" : "3px 10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: statusChange[statusObj.id] ? "pointer" : "",
      lineHeight: 1,
    };
    return (
      <td>
        <div style={style}>
          {statusChange[statusObj.id] && (
            <DropDownList
              value={DefaultName}
              data={statusChange[statusObj.id]}
              onChange={(e) =>
                onChangeOrderStatus(props.dataItem.id, e.target.value.id)
              }
              textField="name"
              defaultItem={DefaultName}
            />
          )}
          {!statusChange[statusObj.id] && DefaultName.name}
        </div>
      </td>
    );
  };

  // settings slider
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    focusOnSelect: false,
    initialSlide: [...days].reverse().findIndex((day) => day.week === "this"),
  };

  const closeHandler = (e) => {
    if (e.target.matches(".k-daterangepicker, .k-daterangepicker *")) {
      setShow(!showState);
    } else if (
      !e.target.matches(".k-calendar, .k-calendar, .k-calendar-td *")
    ) {
      if (showState) {
        setShow(false);
      }

      if (resched) {
        setResched(null);
      }
    }
  };
  const defaultItem = {
    name: "בחר סטטוס",
  };
  const defaultYearItem = {
    name: "בחר שנה",
  };
  const loadFunc = async (event) => {
    if (!loading) {
      
      setSearch(ordersDispatch, {...ordersSearch, skip: 0, pageNumber: ordersSearch.pageNumber + 1});
      
    }

    //     const e = event.nativeEvent;

    //     if (
    //       e.target.scrollTop + 10 >=
    //       e.target.scrollHeight - e.target.clientHeight
    //     ) {
    //       const moreData = [123,123,123]

    //       if (moreData.length > 0) {
    //         // setData(data.concat(moreData));
    //         console.log(moreData);
    //       }
    //     }
  };
  const reset = () => {
    statuses.map((status) => (status.active = 0));
    days.map((day) => (day.active = 0));
    lines.map((line) => (line.active = 1));
    setDefaultValue({ start: null, end: null });
    
    setSearch(ordersDispatch, {...ordersSearch, search: "", days: days, lines: lines, statuses: statuses, years: years, dates: []});
  };

  return (
    <div
      className="orders divAllPage"
      ref={(ref) => setscrollParentRef(ref)}
      onClick={closeHandler}
    >
      {isBrowser && (
        <div className="divAllPage_R">
          <div className="search">
            <div className="search_wrapper">
              <div
                className="BtnLine"
                style={{
                  justifyContent: actions.includes("superActions")
                    ? "start"
                    : "space-between",
                  marginBottom: actions.includes("superActions")
                    ? "30px"
                    : "10px",
                  marginTop: actions.includes("superActions") ? "0" : "10px",
                }}
              >
                <div
                  className="buttons"
                  style={{
                    justifyContent: actions.includes("superActions")
                      ? "space-between"
                      : "start",
                  }}
                >
                  <div className="rightButton">
                    <button
                      className="new-order-btn btn"
                      onClick={
                        !openCustomers
                          ? create
                          : (e) => setShowCustomersPopup(ordersDispatch, true)
                      }
                      disabled={loading}
                    >
                      <i className="fas fa-plus"></i>
                      הזמנה חדשה
                    </button>
                    {actions.includes("specialUpdate") && (
                      <button
                        className="btn updateOrders"
                        onClick={(e) => navigate("/products/")}
                        disabled={loading}
                      >
                        <i className="fas fa-pen-fancy"></i>
                        עדכון הזמנות
                      </button>
                    )}
                    {user?.can_upload_file !== 0 &&
                      actions.includes("superActions") && (
                        <UploadOrdersFile
                          data={state}
                          color={
                            actions.includes("specialUpdate")
                              ? "#f37656"
                              : "#84d0f1"
                          }
                        />
                    )}
                    {actions.includes("forbiddenDates") && (
                      <button
                        className="btn blockDates"
                        onClick={(e) => setShowForbiddenDatesPopup(ordersDispatch, true)}
                        disabled={loading}
                      >
                        <i className="fas fa-times"></i>
                        חסימת תאריכים
                      </button>
                    )}  
                  </div>
                  <div className="leftButtons">
                    <button
                      id="btnExcel"
                      className="btn"
                      onClick={exportToExcel}
                      disabled={isLoadinExcel || loading}
                    >
                      {isLoadinExcel ? (
                        <Loader type="pulsing" />
                      ) : (
                        [
                          <i className="fas fa-file-excel" key={1}></i>,
                          "ייצוא לאקסל",
                        ]
                      )}
                    </button>
                  </div>
                </div>
                {days.findIndex((day) => day.week === "this") !== -1 &&
                  !actions.includes("superActions") &&
                  daysCreate(true)}
                {!actions.includes("superActions") && calanderCreate(true)}
              </div>
              <form onSubmit={onSubmit}>
                <div className="wrapps">
                  {actions.includes("superActions") && (
                    <div className="searchNameId">
                      <i className="fas fa-search"></i>
                      <input
                        className="nameIdInput nameId"
                        type="text"
                        placeholder="הקלידו שם עסק / ID"
                        onChange={onChange}
                        value={search.length == 0 ? ordersSearch.search : search}
                      />
                    </div>
                  )}
                  {days.findIndex((day) => day.week === "this") !== -1 &&
                    actions.includes("superActions") &&
                    daysCreate(false)}

                  {(actions.includes("superActions") && lines.length) > 0 && (
                    <ul className="lines">
                      {lines.map((line) => (
                        <li
                          key={line.id}
                          className={line.active === 1 ? "line-active" : "line"}
                          onClick={() => {
                            searchByLines(line);
                          }}
                        >
                          {line.name}
                        </li>
                      ))}
                    </ul>
                  )}
                  {actions.includes("superActions") && (
                    <div className="filterStatus">
                      <DropDownList
                        value={
                          ordersSearch.statuses.filter(
                            (status) => status.active === 1
                          )[0]
                            ? ordersSearch.statuses.filter(
                                (status) => status.active === 1
                              )[0]
                            : defaultItem
                        }
                        data={statuses.filter(
                          (status) => status.display === "1"
                        )}
                        onChange={onChangeStatus}
                        textField="name"
                        defaultItem={defaultItem}
                      />
                    </div>
                  )}
                  {actions.includes("superActions") && calanderCreate(false)}
                  {actions.includes("superActions") && (
                    <button className="btn reset" onClick={reset}>
                      <i className="fas fa-retweet"></i>איפוס
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {openCustomers && (
            <CustomersPopup
              onClose={popupCloseHandler}
              show={showCustomersPopup}
            />
          )}
          {showForbiddenDatesPopup &&
            <ForbiddenDatesPopup 
              show={showForbiddenDatesPopup}
              onClose={popupCloseForbiddenHandler}/>
          }
          <div
            className="divWarpGrid"
            dir="rtl"
            style={{
              height: actions.includes("superActions")
                ? "calc(100vh - 270px)"
                : "calc(100vh - 210px)",
              opacity: loading ? "0.65" : "1",
            }}
          >
            {loading && <Loader type="infinite-spinner" />}
            <ExcelExport data={allOrdersForExcel ?? []} ref={_export}>
              <Grid
                data={orders.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                  enabled: true,
                  drag: false,
                  cell: false,
                  mode: "multiple",
                }}
                onSelectionChange={onSelectionChange}
                skip={ordersSearch.skip}
                take={ordersSearch.take}
                total={count}
                sort={ordersSort}
                pageable={{
                  buttonCount: 10,
                  info: true,
                  type: "numeric",
                  previousNext: true,
                }}
                onPageChange={pageChange}
                sortable={true}
                onSortChange={sortClick}
                ref={_grid}
              >
                <GridNoRecords>
                  <div>
                    <i className="fas fa-search"></i> לא נמצאו תוצאות
                  </div>
                </GridNoRecords>

                {(columns ?? []).map((column) =>
                  column !== "selectRow" &&
                  column !== "status" &&
                  column !== "actions" &&
                  column !== "id" &&
                  column !== "delivery" ? (
                    <GridColumn
                      key={column}
                      field={column}
                      title={labels[column]}
                      width={widths[column]}
                      cell={regularCell}
                    />
                  ) : column === "status" ? (
                    <GridColumn
                      key={column}
                      field={column}
                      title={labels[column]}
                      width={widths[column]}
                      cell={cellWithBackGround}
                    />
                  ) : column === "actions" ? (
                    <GridColumn
                      key={column}
                      title={labels[column]}
                      width={widths[column]}
                      cell={actionsCell}
                    />
                  ) : column === "delivery" ? (
                    <GridColumn
                      key={column}
                      field={column}
                      title={labels[column]}
                      width={widths[column]}
                      cell={deliveryCell}
                    />
                  ) : column === "selectRow" ? (
                    <GridColumn
                      key={column}
                      headerCell={selectAll}
                      width="50"
                      cell={selectCell}
                    />
                  ) : (
                    <GridColumn
                      key={column}
                      field={column}
                      title={labels[column]}
                      width={widths[column]}
                      cell={idCell}
                    />
                  )
                )}
              </Grid>
            </ExcelExport>
          </div>
        </div>
      )}
      {isMobile && (
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={loadFunc}
          hasMore={hasMore()}
          loader={
            <div className="mobile_loader" key={0}>
              <Loader className="loadMoreLoader" type="infinite-spinner" />
            </div>
          }
          useWindow={false}
          getScrollParent={() => scrollParentRef}
        >
          <OrdersMobile
            onChangeOrderStatus={onChangeOrderStatus}
            orders={orders}
            customersCreate={customersCreate()}
            calanderCreate={calanderCreate(true)}
            yearsCreate={yearsCreate()}
          ></OrdersMobile>
          {openCustomers && (
            <CustomersPopup
              className="mobile_popup"
              onClose={popupCloseHandler}
              show={showCustomersPopup}
            />
          )}
        </InfiniteScroll>
      )}
      <div className="divAllPage_L allPageOrder">
        <OrderItem />
      </div>
      {successPopUp &&
        (updatedNow || submittedNow) && (
          <Dialog
            title={"נפלא:)"}
            onClose={() => hideSuccessPopUp(ordersDispatch)}
            className="pop-up successPopUp"
          >
            <p>
              {updatedNow
                ? "העדכון נשמר בהצלחה,"
                : submittedNow
                ? "הזמנתך נוצרה בהצלחה,"
                : ""}
            </p>
            {(submittedNow && lastSubmitted.date_format) && (
              <p> תאריך המשלוח הוא {lastSubmitted.date_format}</p>
            )}
            <p>
              {updatedNow
                ? "נשמח להמשיך לעמוד לשרותך."
                : submittedNow
                ? "הצוות המסור של עד הגן ישמח לטפל בה בהקדם."
                : ""}
            </p>

            <DialogActionsBar>
              <button
                className="submit btn"
                onClick={() => hideSuccessPopUp(ordersDispatch)}
              >
                אישור
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        
      {showCancelOrderPopUp && (
        <Dialog
          title={"לבטל את ההזמנה?"}
          onClose={() => setshowCancelOrderPopUp(false)}
          className="pop-up"
        >
          <p>לאחר ביטול הזמנה אין אפשרות לשנות לה את הסטטוס.</p>
          <DialogActionsBar>
            <button
              className="cancel btn"
              onClick={() => setshowCancelOrderPopUp(false)}
            >
              ביטול
            </button>
            <button className="submit btn" onClick={() => confirmCancel(true)}>
              אישור
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {askExisting && (
        <Dialog
          title={"קיימת עבורך הזמנה במערכת"}
          onClose={() => setAskExisting(false)}
          className="pop-up"
        >
          <p>מה ברצונך לעשות?</p>
          <DialogActionsBar>
            <button
              className="btn new"
              onClick={() => {
                create();
              }}
            >
              הזמנה חדשה
            </button>
            <button
              className="btn update"
              onClick={() => {
                setAskExisting(false);
                navigate("/orders/" + existingOrder);
              }}
            >
              עדכון הזמנה
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {uploadMessages && uploadMessages.length > 0 && (
        <Dialog
          title="שים לב:"
          onClose={clearUpload}
          className="pop-up"
          height="250px"
        >
          {uploadMessages.map((msg) => {
            return (
              <div>
                <b>{msg}</b>
              </div>
            );
          })}
          <DialogActionsBar>
            <button className="submit btn" onClick={clearUpload}>
              אישור
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {updateMultiplePopup && (
        <Dialog
          className="pop-up"
          title="עדכון מספר רשומות"
          onClose={() => setShowUpdateMultiplePopup(false)}
        >
          <DialogContent>
            <div>
              <b> האם אתה בטוח שברצונך לעדכן את {checkedIds.length} הרשומות המסומנות?</b>{" "}
            </div>
          </DialogContent>
          <DialogActionsBar>
            <button
              className="cancel btn"
              onClick={() => setShowUpdateMultiplePopup(false)}
            >
              ביטול
            </button>
            <button
              className="submit btn"
              onClick={() => confirmUpdateMultiple(true)}
            >
              אישור
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default Orders;
