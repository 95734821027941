import { useEffect, useState } from "react";
import popupStyles from "../../css/custom-popup.module.css"
import PropTypes from "prop-types";
import ReactImageMagnify from 'react-image-magnify';
import Slider from "react-slick";

const ProductImage = (props) => {
    const [show, setShow] = useState(false);

    const closeHandler = (e) => {
      if(e.target.className === popupStyles.overlay || e.target.className === popupStyles.close){
        setShow(false);
        props.onClose();
      }
    };
  
    useEffect(() => {
      setShow(props.show);
    }, [props.show]);
  
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      console.log(props.images);
  return (
    <div id="product-image-popup"
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className={popupStyles.overlay}
      onClick={closeHandler}
    >
        <span className={popupStyles.close} onClick={closeHandler}>
          &times;
        </span>
        <div className={[popupStyles.popup + ' image_popup']}> 
        <h2>{props.title}</h2>
        <Slider {...settings}>

        {props.images.filter((img) => img !== null).map((image) => 
            <ReactImageMagnify {...{
                smallImage: {
                    alt: 'Wristwatch by Ted Baker London',
                    isFluidWidth: true,
                    src: process.env.PUBLIC_URL + `/images` + image
                },
                largeImage: {
                    src: process.env.PUBLIC_URL + `/images` + image,
                    width: 800,
                    height: 1200,
                }, 
                enlargedImageContainerStyle: { background: '#fff', zIndex: 9 },
                enlargedImagePosition: 'over'
            }} />  
        )}
        </Slider>
        
      </div>
    </div>
  );
};

ProductImage.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

export default ProductImage;