/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  useOrders,
  loadOrder,
  getDepartments,
  getOrderNotes,
  addError,
  sendMessage,
  createNote,
  getPriceListProducts,
  submitOrder,
  checkDeliveryDate,
  updateOrder,
  clearSuccess,
  clearErrors,
  emptyOrders,
  clearUploadMessages,
  setEnlarged,
  hideForbiddenNotice,
  toggleDepartment,
  searchByFamily, 
  setOrderSearchFamily,
  setDirty
} from "../../context/orders/OrdersState";

import { useAuth } from "../../context/auth/AuthState";
import AlertContext from "../../context/alert/alertContext";
import { Loader } from "@progress/kendo-react-indicators";
import { useParams, useNavigate } from "react-router-dom";
import Product from "./Product";
import CartItem from "./CartItem";
import ProductsFilter from "./ProductsFilter";
import Title from "./Title";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import UploadFile from "./UploadFile";
import Department from "./Department";
import ProductImage from "./ProductImage";
import { Scrollbars } from "react-custom-scrollbars-rtl";
import BeforeUnloadComponent  from 'react-beforeunload-component';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import OrderMobile from "./OrderMobile";
const Order = () => {
  const [ordersState, ordersDispatch] = useOrders();
  const {
    currentOrder,
    departments,
    families,
    products,
    relevantFamilies,
    relevantDeps,
    sendMessages,
    notes,
    total,
    plProducts,
    error,
    success,
    uploadMessages,
    loading,
    successPopUp,
    missingNoteType,
    enlarged,
    draftStatus,
    actions,
    forbiddenNotice,
    showPrices
  } = ordersState;
  const [authState] = useAuth();
  const { user } = authState;

  const navigate = useNavigate();
  
  // for alerts
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [submitLoad, setSubmitLoad] = useState(false);

  const { id } = useParams();
  const [tab, setTab] = useState("products");
  const [weekOver, setWeekOver] = useState(0);
  const [showWeekOver, setShowWeekOver] = useState(false);
  const [showWeekMin, setShowWeekMin] = useState(false);
  const [monthOver, setMonthOver] = useState(0);
  const [showMonthOver, setShowMonthOver] = useState(false);
  
  const [ignoreBudget,setIgnoreBudget]=useState(false)
  const [ignoreForbidden,setIgnoreForbidden]=useState(false)
  const [saving,setSaveing]=useState(false)
  const [sendWithNoBudget, setSendWithNoBudget]=useState(actions?.includes('sendWithNoBudget'))
  const [updateForbidden, setUpdateForbidden]=useState(actions?.includes('updateForbidden'))

  const [isDirty, setIsDirty] = useState(false);
  const [initialTotal, setInitialTotal] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [totalWithFee, setTotalWithFee] = useState(0);

  console.log("send with no budget : " + sendWithNoBudget);
  console.log("update forbidden : " + updateForbidden);
  console.log("ignore budget " + ignoreBudget);

  useEffect(() => {
    // if UnAuthorized - refresh page
    if (
      typeof error !== "undefined" &&
      error !== null &&
      typeof error.data !== "undefined" &&
      error.data.status === 401
    ) {
      window.location.reload();
    }
    if (error !== "") {
      setAlert(error, "danger");
      clearErrors(ordersDispatch);
    }
  }, [error]);

  useEffect(()=>{
    
    console.log("in useEffect " + ignoreBudget + ", " + ignoreForbidden);
    if (ignoreBudget) {
      showSendPopUp();
    }
  },[ignoreBudget])
  
  useEffect(()=>{
    
    console.log("in useEffect " + ignoreBudget + ", " + ignoreForbidden);
    if (ignoreForbidden) {
      showSendPopUp();
    }
  },[ignoreForbidden])

  useEffect(() => {
    if (currentOrder && currentOrder.delivery_fee && total && total < currentOrder.max_for_fee) {
      setDeliveryFee(currentOrder.delivery_fee);
      setTotalWithFee(total + currentOrder.delivery_fee);
    } else {
      setDeliveryFee(0);
      setTotalWithFee(total);
    }


    console.log("total effect " + total + ", with fee: " + totalWithFee);
  }, [total, currentOrder?.delivery_fee]);


  useEffect(() => {
    loadOrder(ordersDispatch, id);
    getDepartments(ordersDispatch);
    getPriceListProducts(ordersDispatch, id);
    setTimeout(showHideMissing, 1500);
  }, [ordersDispatch]);

  useEffect(() => {
    setShowPopUp(!showPopUp && (ignoreForbidden || forbiddenNotice === 2));
  }, [forbiddenNotice]);

  const [showPopUp, setShowPopUp] = useState(false);
  const showSendPopUp = async() => {
    setSaveing(true) 
    console.log("!!!!!!!!!!!!!!!!!!!!" + (currentOrder?.actions?.indexOf("send-update") > -1) + "......" );
    
    if (weekOver > 0 && !ignoreBudget) {
      setShowWeekOver(true);

      // check also monthly
      if (monthOver > 0) {
        setShowMonthOver(true);
      }

      // chek also min weekly
      if (totalWithFee < currentOrder.min_order_weekly) {
        setShowWeekMin(true);
      }

    } else if (monthOver > 0 && !ignoreBudget) {
      setShowMonthOver(true);

      // chek also min weekly
      if (totalWithFee < currentOrder.min_order_weekly) {
        setShowWeekMin(true);
      }

    } else if (totalWithFee < currentOrder.min_order_weekly && !ignoreBudget) {
      setShowWeekMin(true);
    } else { 
      if (currentOrder?.actions?.indexOf("send-update") > -1 && !showPopUp && !ignoreForbidden) {
        checkDeliveryDate(ordersDispatch, id);
      } else {
        
        setShowPopUp(!showPopUp);
        
        if (!ignoreForbidden) {
          hideForbiddenNotice(ordersDispatch);  // to set forbiddenNotice to 0 again
        }
      }
    }
  };

  const [showMissing, setShowMissing] = useState(false);
  const showHideMissing = () => {
    setShowMissing(!showMissing);
  };

  console.log("total::: " + total);
  console.log("delivery: " + currentOrder?.fields?.delivery_date);
  
  useEffect(() => {
    console.log("total with fee = " + totalWithFee);
    if (currentOrder) {
      if (currentOrder.max_order_weekly > 0) {
        let over = totalWithFee - currentOrder.max_order_weekly;

        console.log("over = " + over);
        setWeekOver(over);
        setShowWeekOver(over > 0);
        
      }
      if (currentOrder.max_orders_monthly > 0) {
        let over = totalWithFee - currentOrder.priorityTotal -
        parseInt(currentOrder.budget_left);

        console.log("totalWithFee = " + totalWithFee);
        console.log("p_total = " + currentOrder.priorityTotal);
        console.log("budgetleft = " + currentOrder.budget_left);
        console.log("over = " + over);
        setMonthOver(over);
        setShowMonthOver(over > 0);
        
      }
    }

    // eslint-disable-next-line
  }, [totalWithFee, currentOrder?.fields?.delivery_date]);

  useEffect(()=>{
   setSendWithNoBudget(actions?.includes('sendWithNoBudget'))
   setUpdateForbidden(actions?.includes('updateForbidden'))
  },[actions]) 

  useEffect(() => {
    if (successPopUp && currentOrder === null) {
      emptyOrders(ordersDispatch);
      navigate("/orders/");
    }
    if (currentOrder) {
      if (Object.keys(currentOrder).length !== 0) {
        getOrderNotes(ordersDispatch, currentOrder);
      }
    }
    
  }, [currentOrder]);

  useEffect(() => {
    if (initialTotal === 0 && total > 0) {
      console.log("set initial to " + total);
      setInitialTotal(total);
    } else {
      if (total !== initialTotal) {
        console.log("init dirty")
        setIsDirty(true);
      }
    }
  }, [total]);
  

  const sendClick = (e) => {
    sendMessage(ordersDispatch, id, document.getElementById("inputText").value);
    document.getElementById("inputText").value = "";
  };

  const noteClick = (e) => {
    createNote(ordersDispatch, id, {
      text: document.getElementById("inputText").value,
    });
    document.getElementById("inputText").value = "";
  };

  useEffect(() => {
    if (success !== "" && success !== null) {
      setAlert(success, "success");
      clearSuccess(ordersDispatch);
    }
  }, [success]);

  if (departments === null || departments.length === 0) {
    return "";
  }

  const rightProducts = plProducts;

  const onSubmitUpdate = () => {
    showSendPopUp();
    setSubmitLoad(true);
    updateOrder(ordersDispatch, currentOrder.fields.id);
    setIgnoreBudget(false)

  };

  const onSubmit = () => {
    showSendPopUp();
    setSubmitLoad(true);
    submitOrder(
      ordersDispatch,
      currentOrder.fields.id,
      currentOrder.fields.delivery_date,
      currentOrder.fields.customer_id,
      products
    );
    setIgnoreBudget(false)

  };

  const clearUpload = (e) => {
    clearUploadMessages(ordersDispatch);
  };

  // close all departments
  const closeDepartments = (e) => {
    departments.map((department) => {
        if (department.open === 1) {
            department.open = 0;
            toggleDepartment(ordersDispatch, department);   
        }
        return false;
    })

    // get all products
    searchByFamily(ordersDispatch, currentOrder.fields.id);    

    // set no family
    setOrderSearchFamily(ordersDispatch, 0)
  }

  const myHandleLeave = ({ onLeave, onStay }) => {
    
    return <Dialog
    title='שים לב:'
    onClose={onStay}
    open={isDirty}
    className="pop-up pop-up-orange"
  >
     על מנת שהעדכון יתבצע יש ללחוץ על כפתור שליחת עדכון. <br></br>האם אתה בטוח שברצונך לצאת?
    <DialogActionsBar>
      <button
        className="cancel btn"
        onClick={onLeave}
      >
        אני רוצה לצאת
      </button>
      <button
        className="submit btn"
        onClick={onStay}
      >
        אני רוצה להשאר
      </button>
    </DialogActionsBar>
  </Dialog> ;
  }
  
  return (
    <BeforeUnloadComponent
    blockRoute={currentOrder?.fields?.status > draftStatus && isDirty}
    modalComponentHandler={({handleModalLeave, handleModalCancel})=>{
      return myHandleLeave({onLeave: handleModalLeave, onStay: handleModalCancel});
  }}
    
    >
    <div className="divAllPage ordersPage d-flex">
      <Title navigate={navigate}/>
      {isBrowser && (
        <div className="d-flex all_content">
          <div className="card departments">
            <div className="card_wrapper">
              <div className="card_title" onClick={closeDepartments}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.862"
                  height="20.918"
                  viewBox="0 0 20.862 20.918"
                >
                  <g
                    id="Group_130"
                    data-name="Group 130"
                    transform="translate(71.509 -3.494)"
                  >
                    <g
                      id="Group_131"
                      data-name="Group 131"
                      transform="translate(-70.606 4.394)"
                    >
                      <path
                        id="Path_64"
                        data-name="Path 64"
                        d="M22,17a2.9,2.9,0,0,1-3.041,3.041A2.9,2.9,0,0,1,15.914,17"
                        transform="translate(-9.434 -10.048)"
                        fill="none"
                        stroke="#bad564"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.8"
                      />
                      <path
                        id="Path_65"
                        data-name="Path 65"
                        d="M13.563,7.735a3.259,3.259,0,0,0,6.467.575,2.366,2.366,0,0,0-.06-1L18.342,1H3.135L1.128,7.424A2.413,2.413,0,0,0,1.078,8.8a3.149,3.149,0,0,0,3.144,2.2A3.259,3.259,0,0,0,7.48,7.735"
                        transform="translate(-1 -1)"
                        fill="none"
                        stroke="#bad564"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.8"
                      />
                      <path
                        id="Path_66"
                        data-name="Path 66"
                        d="M20.508,27v5.214A2.607,2.607,0,0,1,17.9,34.821H7.473a2.607,2.607,0,0,1-2.607-2.607V27"
                        transform="translate(-3.186 -15.703)"
                        fill="none"
                        stroke="#bad564"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.8"
                      />
                      <path
                        id="Path_67"
                        data-name="Path 67"
                        d="M21.555,27v4.345h-8.69V27"
                        transform="translate(-7.71 -15.703)"
                        fill="none"
                        stroke="#bad564"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.8"
                      />
                    </g>
                  </g>
                </svg>
                המחלקות שלנו
              </div>

              <div className="all_departments">
                {departments
                  .filter((d) => relevantDeps?.includes(d.id + ""))
                  .map((department) => (
                    <Department
                      allDepartments={departments}
                      key={department.id}
                      department={department}
                      families={families.filter(
                        (family) =>
                          +family.department_id === department.id &&
                          relevantFamilies?.includes(family.id + "")
                      )}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="products">
            <div className="productsHeader d-flex">
              <ProductsFilter />
              {user?.can_upload_file !== 0 && <UploadFile />}
            </div>
            <div className="products_disc">
                הנתונים המדויקים מופיעים על גבי המוצר, אין להסתמך על הפירוט המופיע באתר, יתכנו טעויות או אי התאמות, יש לקרוא את המופיע על גבי אריזת המוצר לפני השימוש.
                <br/>
התמונות והתאריכים המופיעים הינם להמחשה בלבד ואין להסתמך עליהם.
</div>
            <div className={"divWarpGrid" + (!showPrices ? " no_price" : "")}>
              <div className="products-title">
                <div className='table_pic'>תמונה</div>
                <div className="table_desc">מוצר</div>
                <div className="table_qty">כמות</div>
                <div className="table_unit">יחידה</div>
                {showPrices && <div className="table_price">מחיר</div>}
              </div>
              <Scrollbars style={{ direction: "rtl", height: 500 }}>
                {rightProducts.map((product) => (
                  <Product key={product.id} product={product} showPrices={showPrices}/>
                ))}
              </Scrollbars>
            </div>
          </div>

          <div className="card cart">
            <div className="card_wrapper">
              <div className="card_tabs">
                <button
                  className={
                    tab === "products"
                      ? "card_tabsBtn cart active"
                      : "card_tabsBtn cart inactive"
                  }
                  onClick={() => {
                    setTab("products");
                  }}
                  style={{
                    width: sendMessages || notes.length > 0 ? "50%" : "100%",
                    justifyContent:
                      sendMessages || notes.length > 0 ? "center" : "start",
                    padding:
                      sendMessages || notes.length > 0 ? "0 5px" : "0 25px",
                  }}
                >
                  <svg
                    width="21.525"
                    height="23.709"
                    viewBox="0 0 21.525 23.709"
                  >
                    <g id="add-to-cart" transform="translate(0.251 0.25)">
                      <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M218.432,132.006l-2.06,2.06a.68.68,0,0,1-.962,0l-2.06-2.06a.68.68,0,1,1,.962-.962l.9.9v-2.728a.68.68,0,0,1,1.36,0v2.728l.9-.9a.68.68,0,0,1,.962.962Zm0,0"
                        transform="translate(-207.269 -122.709)"
                        fill="#bad564"
                        stroke="#bad564"
                        strokeWidth="0.5"
                      />
                      <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M.68,16.023H13.552a1.358,1.358,0,0,0,1.308-1.2l.033-.358H3.271A2.728,2.728,0,0,1,.608,12.038l-.6-6.57A2.316,2.316,0,0,1,2.344,2.913h13.6l.044-.481A2.693,2.693,0,0,1,18.587,0h1.758a.68.68,0,0,1,0,1.36H18.587a1.323,1.323,0,0,0-1.245,1.195l-.1,1.107h0L16.215,14.951a2.728,2.728,0,0,1-2.663,2.432H.68a.68.68,0,0,1,0-1.36ZM2.344,4.273a.957.957,0,0,0-.979,1.072l.6,6.57a1.358,1.358,0,0,0,1.309,1.2H15.017l.8-8.837Zm0,0"
                        transform="translate(0)"
                        fill="#bad564"
                        stroke="#bad564"
                        strokeWidth="0.5"
                      />
                      <path
                        id="Path_36"
                        data-name="Path 36"
                        d="M338.14,417.73A2.137,2.137,0,1,1,336,419.867,2.139,2.139,0,0,1,338.14,417.73Zm0,2.913a.777.777,0,1,0-.777-.777A.778.778,0,0,0,338.14,420.644Zm0,0"
                        transform="translate(-334.484 -398.795)"
                        fill="#bad564"
                        stroke="#bad564"
                        strokeWidth="0.5"
                      />
                      <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M145.344,417.73a2.137,2.137,0,1,1-2.137,2.137A2.139,2.139,0,0,1,145.344,417.73Zm0,2.913a.777.777,0,1,0-.777-.777A.777.777,0,0,0,145.344,420.644Zm0,0"
                        transform="translate(-132.947 -398.795)"
                        fill="#bad564"
                        stroke="#bad564"
                        strokeWidth="0.5"
                      />
                    </g>
                  </svg>
                  <p
                    className={
                      sendMessages || notes.length > 0 ? "twoTabs" : "oneTab"
                    }
                  >
                    עגלת קניות <span>({products.length} מוצרים)</span>
                  </p>
                </button>
                {(sendMessages || notes.length > 0) && (
                  <button
                    className={
                      tab === "notes"
                        ? "card_tabsBtn active notes"
                        : "card_tabsBtn inactive notes"
                    }
                    onClick={() => {
                      setTab("notes");
                    }}
                  >
                    <svg
                      id="Group_192"
                      data-name="Group 192"
                      width="22.864"
                      height="20.398"
                      viewBox="0 0 22.864 20.398"
                    >
                      <path
                        id="Path_72"
                        data-name="Path 72"
                        d="M2.974,12.444H1V1H16.8V3.081"
                        transform="translate(1.215 1.158)"
                        fill="none"
                        stroke="#bad564"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_73"
                        data-name="Path 73"
                        d="M7,7v9.7h7.5v3.086L17.581,16.7h3.527V7Z"
                        transform="translate(-0.452 -0.452)"
                        fill="none"
                        stroke="#bad564"
                        strokeWidth="2"
                      />
                    </svg>
                    עדכונים
                  </button>
                )}
              </div>
              {tab === "products" && (
                <Scrollbars style={{ height: showPrices ? "calc(100% - 258px)" : "calc(100% - 138px)" }}>
                  {products.map((product) => (
                    <CartItem key={product.id} item={product} showPrices={showPrices}/>
                  ))}
                </Scrollbars>
              )}

              {(tab === "products" && currentOrder) && (
                <div className="orderSum">
                  
                  {showPrices &&
                    <><div className="orderSum_desc">
                      <div className="sum-text">
                        <span className="sum-text_subtitle">סה"כ
                        <span className="sum-text_subtitle">  (כולל מע"מ)</span>
                        </span>
                      </div>
                      
                      <div className="sum-number-small">
                        {Math.round(total * 100) / 100}{" "}
                        <i className="fas fa-shekel-sign"></i>
                      </div>
                    </div>
                    <div className="orderSum_desc">
                      <div className="sum-text">
                        <span className="sum-text_subtitle">דמי משלוח
                        </span>
                      </div>
                      <div className="sum-number-small">
                        {Math.round(deliveryFee * 100) / 100}{" "}
                        <i className="fas fa-shekel-sign"></i>
                      </div>
                    </div>
                    <div className="orderSum_desc">
                      <div className="sum-text">
                        <span className="sum-text_title">לתשלום
                        </span>
                      </div>
                      <div className="sum-number">
                      {Math.round(totalWithFee * 100) / 100}{" "}
                        <i className="fas fa-shekel-sign"></i>
                      </div>
                    </div>
                  </>
                  }
                  {currentOrder.actions?.length > 0 && (
                    <button
                      disabled={loading || !total}
                      onClick={showSendPopUp}
                      className={"send-order-btn" + ((loading || !total) ? " disabled-btn" : "")}
                    >
                  {loading ?
                    <Loader type="pulsing" />
                    :
                      currentOrder.actions?.indexOf("send-update") > -1
                        ? "שליחת עדכון"
                        : "שליחת הזמנה"
                      }
                    </button>
                  )}
                </div>
              )}
              {tab === "notes" && sendMessages && (
                <div className="card_top">
                  <div className="card_text">
                    <textarea
                      id="inputText"
                      type="text"
                      name="name"
                      placeholder="כתוב כאן..."
                    ></textarea>
                  </div>
                  <div className="card_btns" id="divBtnPopup">
                    <button
                      className="btnPopup btnSendSMS"
                      id="btnSendSMS"
                      onClick={sendClick}
                    >
                      <i className="fas fa-sms"></i>שליחת SMS
                    </button>
                    <button
                      className="btnPopup btnAddMessage"
                      id="btnAddMessage"
                      onClick={noteClick}
                    >
                      <i className="fas fa-comment-dots"></i>הוספת הודעה
                    </button>
                  </div>
                </div>
              )}
              <div
                className={
                  sendMessages ? "master card_content" : "card_content"
                }
              >
                {tab === "notes" && (
                  <div className="card_comments">
                    {notes.map((note) => {
                      return (
                        <div key={note.id} className="mess_line">
                          <div className="note">
                            <div
                              className="avatar"
                              style={{
                                backgroundColor: note.color,
                                color: note.textColor,
                              }}
                            >
                              {note.name}
                            </div>
                            <div
                              className="mess_text"
                              dangerouslySetInnerHTML={{ __html: note.text }}
                            ></div>
                          </div>
                          <div className="mess_date">{note.date}</div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && <div><OrderMobile showSendPopUp={showSendPopUp} cartProducts={products} products={rightProducts} deliveryFee={deliveryFee} totalWithFee={totalWithFee}></OrderMobile> </div>}
      {submitLoad && loading && (
        <div className="overley_page">
          <Loader type="converging-spinner" />
        </div>
      )}
      {(showPopUp) &&(
        <Dialog
          title={
            currentOrder?.actions?.indexOf("send-update") > -1
              ? "האם לעדכן את ההזמנה?"
              : "האם לשלוח את ההזמנה?"
          }
          onClose={(e) => {showSendPopUp();setSaveing(false);}}
          className="pop-up"
        >
          <p>
            {currentOrder?.actions?.indexOf("send-update") > -1
              ? "לאחר שליחת עדכון, תוכל לשוב ולעדכן את ההזמנה לזמן מוגבל."
              : "לאחר שליחת הזמנה, תוכל לשוב ולעדכן את ההזמנה לזמן מוגבל."}
          </p>
          <DialogActionsBar>
          <button
              className="submit btn"
              onClick={
                currentOrder?.actions?.indexOf("send-update") > -1
                  ? onSubmitUpdate
                  : onSubmit
              }
            >
              אישור
            </button>
            <button className="cancel btn" onClick={(e)=>{showSendPopUp(); setIgnoreBudget(false); setIgnoreForbidden(false);setSaveing(false);} }>
              ביטול
            </button>
          </DialogActionsBar>
        </Dialog>
      )}

      {showMissing && notes.filter((note) => note.type === missingNoteType).length > 0 &&
      <Dialog
          title='שים לב:'
          onClose={showHideMissing}
          className="pop-up"
        >
           {notes.filter((note) => note.type === missingNoteType).map((note) => {
              return (
                <div><b>{note.text}</b></div>
              );
            })}
          <DialogActionsBar>
            <button
              className="submit btn"
              onClick={showHideMissing}
            >
              אישור
            </button>
          </DialogActionsBar>
        </Dialog>
        }

      {uploadMessages && uploadMessages.length > 0 && (
        <Dialog
        title='שים לב:'
        onClose={clearUpload}
        className="pop-up"
        height="250px"
      >
         {uploadMessages.map((msg) => {
            return (
              <div><b>{msg}</b></div>
            );
          })}
        <DialogActionsBar>
          <button
            className="submit btn"
            onClick={clearUpload}
          >
            אישור
          </button>
        </DialogActionsBar>
      </Dialog>
      )}
        
      {(forbiddenNotice === 1 && (!updateForbidden || !saving)) &&
        <Dialog
        title={"מצטערים..."}
        onClose={() => hideForbiddenNotice(ordersDispatch)}
        className="pop-up successPopUp pop-up-orange"
        >
        <p>כבר לא ניתן לשלוח את ההזמנה לעדכון. אנא פנה למשרד</p>
        <DialogActionsBar>
          
          <button
            className="submit btn"
            onClick={() => hideForbiddenNotice(ordersDispatch)}
          >
            אישור
          </button>
        </DialogActionsBar>
        </Dialog>
      }
      {(forbiddenNotice === 1 && updateForbidden && saving) &&
        <Dialog
        title={"שים לב:"}
        onClose={() => {hideForbiddenNotice(ordersDispatch);setSaveing(false);}}
        className="pop-up successPopUp pop-up-orange"
        >
        <p>תאריך המשלוח של ההזמנה קרוב מידי או חסום לעדכון</p>
        <DialogActionsBar>
          <button
            className="cancel btn"
            onClick={(e) => {hideForbiddenNotice(ordersDispatch);setSaveing(false);}}
          >
            ביטול
          </button>
          <button className="submit btn" onClick={(e) => {setIgnoreForbidden(true);hideForbiddenNotice(ordersDispatch);}}>
            שליחה בכל זאת
          </button>
        </DialogActionsBar>
        </Dialog> 
      }
      
      {(showMonthOver || showWeekOver || showWeekMin) &&
        <Dialog
        title='שים לב:'
        onClose={(e) => {setShowWeekOver(false);setShowMonthOver(false);setShowWeekMin(false);setSaveing(false);}}
        className="pop-up pop-up-orange"
        >
          {showWeekOver && <div><b>{"הינך חורג מהתקציב השבועי" + (showPrices ? (" ב - " + weekOver.toFixed(2) + " ₪") : "")}</b></div>}
          {showMonthOver && <div><b>{"הינך חורג מהתקציב החודשי" + (showPrices ? (" ב - " + monthOver.toFixed(2) + " ₪") : "")}</b></div>}
          {showWeekMin && <div><b>{"סכום ההזמנה נמוך מהמינימום המותר " + (showPrices ? ("(" + currentOrder.min_order_weekly + " ₪)" ) : "")}</b></div>}
        {(!sendWithNoBudget || !saving) &&<DialogActionsBar>
          <button
            className="submit btn"
            onClick={(e) =>{setShowMonthOver(false);setShowWeekOver(false);setShowWeekMin(false);}}
          >
            אישור 
          </button>
        </DialogActionsBar>}
        { (sendWithNoBudget && saving) &&<DialogActionsBar>
          <button
            className="cancel btn"
            onClick={(e) => {setShowMonthOver(false);setShowWeekOver(false);setShowWeekMin(false);setSaveing(false);}}
          >
            ביטול
          </button>
          <button className="submit btn" onClick={(e) => {setIgnoreBudget(true);setShowMonthOver(false);setShowWeekOver(false);setShowWeekMin(false);}}>
            שליחה בכל זאת
          </button>
        </DialogActionsBar>}
      </Dialog>
      }

      {enlarged && enlarged.image && (
        <ProductImage
          show={true}
          onClose={(e) => setEnlarged(ordersDispatch, null)}
          images={[enlarged.image, enlarged.image_back]}
          title={enlarged.description}
        />
      )}
    
    </div>
    </BeforeUnloadComponent>
  );
};

export default Order;
