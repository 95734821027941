import React, {useState} from 'react'

const Register = () => {
    const [user, setUser] = useState({
        name: '',
        email: ''
    });

    const {name, email, phone} = user;

    const onChange = e => {
        setUser({...user, [e.target.name]: e.target.value});
    };

    return (
        <div className='form-container'>
            <h1>
                Account <span className="text-primary">Register</span>
            </h1>
            <form>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" value={name} onChange={onChange} required/>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input type="text" name="email" value={email} onChange={onChange} required/>
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input type="text" name="phone" value={phone} onChange={onChange} required/>
                 </div>
                 <input type="submit" value="Register" className="btn btn-primary btn btn-block" />
            </form> 
        </div>
    )
}

export default Register
