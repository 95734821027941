import {
    CUSTOMERS_ERROR,
    CUSTOMERS_LOOKUP,
    GET_CUSTOMERS,
    SET_CURRENT_CUSTOMER,
    REMOVE_CURRENT_CUSTOMER,
    GET_CUSTOMER_NOTES,
    GET_CUSTOMER_ORDER_NOTES,
    CREATE_CUSTOMER_NOTE,
    GET_ALL_CUSTOMERS,
    SET_LOADING,
    SEND_SMS
} from '../types';

const customersReducer = (state, action) => {

    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
        }       
        case GET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload.rows,
                count: action.payload.count,
                error: null
            }
        case GET_ALL_CUSTOMERS:             
            return {
                ...state,
                allCustomers: action.payload.rows,
            }
        case CUSTOMERS_LOOKUP:
            return {
                ...state,
                columns: action.payload.gridSettings.customers.columns,
                labels: action.payload.labels,
                orderStatuses: action.payload.lookup_order_statuses
            }
        case GET_CUSTOMER_NOTES:
            return {
                ...state,
                notes: action.payload
            }
        case CREATE_CUSTOMER_NOTE:
        case SEND_SMS:
            return {
                ...state,
                notes: [action.payload, ...state.notes]
            }
        case GET_CUSTOMER_ORDER_NOTES:
            return {
                ...state,
                orderNotes: action.payload
            }    
        case SET_CURRENT_CUSTOMER:
            return {
                ...state,
                current: action.payload
            };
        case REMOVE_CURRENT_CUSTOMER:
            return {
                ...state,
                current: ''
            }; 
        case CUSTOMERS_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
};

export default customersReducer;