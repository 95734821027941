import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardActions,
  CardImage,
  CardSubtitle,
  Avatar,
} from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import {
  duplicateOrder,
  setCurrent,
  useOrders,
} from "../../context/orders/OrdersState";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";

import ellipsis from "../../images/ellipsis.png";
import { padding } from "@mui/system";
import { useNavigate } from "react-router-dom";
const OrderItemMobile = (props) => {
  const { order, onChangeOrderStatus } = props;
  const [ordersState, ordersDispatch] = useOrders();
  const { statuses, statusChange, openCustomers, showPrices } = ordersState;
  const anchor = React.useRef(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  const navigate = useNavigate();
  const displayOrder = () => {
    order.actions?.indexOf("edit") > -1
      ? navigate("/cart/" + order.id)
      : setCurrent(ordersDispatch, order);
  };
  const getStatus = (order) => {
    let statusId = parseInt(order.status);
    const statusObj = statuses.filter((status) => statusId === status.id)[0];
    const statusColor = statusObj?.color;
    const DefaultName = statusObj;

    return (
      <div
        className="statusWrap divWarpGrid"
        style={{
          backgroundColor: statusObj?.color,
          padding: statusChange[statusId] ? "0" : "3px 10px",
        }}
      >
        {statusChange[statusId] ? (
          <DropDownList
            value={DefaultName}
            data={statusChange[statusObj?.id]}
            onChange={(e) => onChangeOrderStatus(order.id, e.target.value.id)}
            textField="name"
            defaultItem={DefaultName}
          />
        ) : (
          <div onClick={displayOrder}>{DefaultName.name}</div>
        )}
      </div>
    );
  };
  return (
    <Fragment>
      <Card className="orderCard">
        <CardBody className="orderCardBody" >
          <div onClick={displayOrder}>
            <div className="bold_prop">
              {" "}
              הזמנה {order.id}{" "}
              {order.customer_name && openCustomers && (
                <Fragment>
                  {" "}
                  | <span className="bold_prop">{order.customer_name}</span>
                </Fragment>
              )}
            </div>

            {/* <div>סכום</div> */}

            {order.line && openCustomers && <div>קו חלוקה: {order.line}</div>}
            {order.delivery_address && (
              <div>כתובת משלוח: {order.delivery_address}</div>
            )}
            <div className="bold_prop">
              חלוקה: יום {order.day} {order.delivery}
            </div>
            {order.products_number && <div className="bold_prop">
              {order.products_number} מוצרים {showPrices ? (' | ' + order.amount_includes_vat + ' ש"ח') : ''}
            </div>}
          </div>
          <div className="cardActions">
            {order.actions?.indexOf("duplicate") > -1 && (
              <div className="duplicate_action">
                <button className="actions" onClick={onClick} ref={anchor}>
                  <img className="ellipsis" src={ellipsis}></img>
                </button>
                
                {/* <Popup
                  anchor={anchor.current}
                  animate={false}
                  show={show}
                  popupClass={"popup-actions-content"}
                > */}
                  <button
                    className="duplicate_btn"
                    onClick={() => duplicateOrder(ordersDispatch, order.id)}
                  >
                    שכפול הזמנה
                  </button>
                {/* </Popup> */}
              </div>
            )}
            {/* {order.showId && (
              <button
                className="actions"
                onClick={() => setCurrent(ordersDispatch, order)}
              >
                <Tooltip anchorElement="target" position="top">
                  <i title="פירוט הזמנה" className="fas fa-info-circle"></i>
                </Tooltip>
              </button>
            )}
            {order.actions?.indexOf("duplicate") > -1 && (
              <button
                className="actions"
                onClick={() =>
                  duplicateOrder(
                    ordersDispatch,
                   order.id
                  )
                }
              >
                <Tooltip anchorElement="target" position="top">
                  <i className="far fa-clone" title="שכפול"></i>
                </Tooltip>
              </button>
            )}
            {order.actions?.indexOf("edit") > -1 && (
              <button
                className="actions"
                onClick={() => navigate("/cart/" + order.id)}
              >
                <Tooltip anchorElement="target" position="top">
                  <i className="far fa-edit" title="עריכה"></i>
                </Tooltip>
              </button>
            )} */}
          </div>
        </CardBody>{" "}
        <div className="cardFooter">
          {" "}
        <div>  {order.modified&&<div onClick={displayOrder}>ת.עדכון: {order.modified}</div>}</div>
          <Fragment>{getStatus(order)} </Fragment>
        </div>
      </Card>
    </Fragment>
  );
};

export default OrderItemMobile;
