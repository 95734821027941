import React from 'react';
import { useOrders, searchByText, setOrderSearch} from '../../context/orders/OrdersState';

const ProductsFilter = () => {
    const [ordersState, ordersDispatch] = useOrders();
    const {currentOrder, orderSearchText} = ordersState;

    const onChange = e => {
        setOrderSearch(ordersDispatch, e.target.value)
        searchByText(ordersDispatch, currentOrder.fields.id, e.target.value);    
    }

    return (
        <div className='search'>
            <div className="search_wrapper">
                <div className='searchNameId'>
                    <i className="fas fa-search"></i>
                    <input className='nameIdInput' type="text" value={orderSearchText} placeholder="הקלידו שם מוצר / קטגוריה / ברקוד" onChange={onChange} />
                </div>
            </div>
        </div>
    )
}

export default ProductsFilter
