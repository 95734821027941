/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  useOrders,
  updateStatus,
  getLookup
} from "../../context/orders/OrdersState";
import { useParams } from "react-router-dom";
import Title from "./Title";
import { useNavigate } from "react-router-dom";

const NotNeeded = () => {
  const [ordersState, ordersDispatch] = useOrders();
  const navigate = useNavigate();

  const { id } = useParams();
  const { noNeedStatus } = ordersState;

  // getting lookup at the first load to get the NoNeedStatus
  useEffect(() => {
    getLookup(ordersDispatch);
  }, [ordersDispatch]);

  // updating status
  useEffect(() => {
    if (noNeedStatus) {
      updateStatus(ordersDispatch, id, noNeedStatus);
    }
  }, [noNeedStatus]);


  return (
    <div className="divAllPage ordersPage d-flex">
      <div class="thanks_middle">תודה רבה!</div>
    </div>
  );
};

export default NotNeeded;
