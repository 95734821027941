import { useEffect, useState } from "react";
import {
  useOrders,
  updateProduct,
  addProduct,
  deleteProduct,
} from "../../context/orders/OrdersState";

const Quantity = ({ product, orderId }) => {
  const ordersDispatch = useOrders()[1];
  const { quantity } = product;
  const [updateHelper, setUpdateHelper] = useState(0);
  
  useEffect(() => {
    if (updateHelper > 0) {
      const timer = setTimeout(UpdateOnServer, 500);
      return () => clearTimeout(timer);
    }
  }, [updateHelper]);

  // document.body.onclick = function() {};
  const upClick = (e) => {
    if (product.quantity === null || product.quantity === 0) {
      product.quantity = 1;
      addProduct(ordersDispatch, orderId, product, false, true);
    } else if (parseInt(product.quantity) < 1000) {
      const oldQuantity = product.quantity;
      product.quantity = parseInt(product.quantity) + 1;
      updateProduct(ordersDispatch, orderId, product, false, oldQuantity);
    }
    setUpdateHelper(updateHelper+1);
  };
  const downClick = (e) => {
    if (parseInt(product.quantity) > 0) {
      // delete product
      if (parseInt(product.quantity) === 1) {
        product.quantity = 0;
        deleteProduct(ordersDispatch, orderId, product);
      } else {
        const oldQuantity = product.quantity;
        product.quantity = parseInt(product.quantity) - 1;
        updateProduct(ordersDispatch, orderId, product, false, oldQuantity);
      }
    }
    setUpdateHelper(updateHelper+1);

  };

  // changing quantity input
  const onChange = (e) => {
    // add new product or change quantity
    // eslint-disable-next-line eqeqeq
    
    if (
      e.target.value == +e.target.value &&
      parseInt(e.target.value) > 0 &&
      parseInt(e.target.value) <= 1000 &&
      Math.round(e.target.value) == e.target.value
    ) {
      if (product.quantity === 0 || product.quantity === null) {
        product.quantity = e.target.value;
        addProduct(ordersDispatch, orderId, product, false, true);
      } else {
        const oldQuantity = product.quantity;
        product.quantity = e.target.value;
        updateProduct(ordersDispatch, orderId, product, false, oldQuantity);
      }
    }

    // delete product
    if (
      product.quantity !== 0 &&
      (e.target.value == 0 || e.target.value === "")
    ) {
      // disabling - not to interupt in the delete process. will be inabled in onBlur
      e.target.disabled = true;
      product.quantity = 0;
      deleteProduct(ordersDispatch, orderId, product);
    }
    setUpdateHelper(updateHelper+1);

  };

  /**
   * when leaving field - call update with server = true
   */
  const onBlur = (e) => {
    // eslint-disable-next-line eqeqeq
    // if (
    //   e.target.value == +e.target.value &&
    //   parseInt(e.target.value) > 0 &&
    //   parseInt(e.target.value) <= 1000
    // ) {
    //   // updating with true to indicate server update
    //   updateProduct(ordersDispatch, orderId, product, true);
    // }
    e.target.disabled = false;
  };

  const UpdateOnServer = (e) => {
    if (parseInt(product.quantity) >= 1) {
      updateProduct(ordersDispatch, orderId, product, true);
    }
  };

  const onClick = (e) => {
    e.target.select();
  };

  // this prevents the scrolling on the number to change it
  const onWheel = (e) => {
    e.target.blur();
  };

  return (
    <div className="table_qty">
      <button className="plus-btn" onClick={upClick}>
        <i className="fas fa-plus"></i>
      </button>
      <input
        className={
          product.is_missing == 1  ? "quantity-input missing" : (quantity > 0 ? "quantity-input quantity-pos" : "quantity-input")
        }
        type="number"
        value={quantity ?? 0}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        max="999"
        onWheel={onWheel}
      />
      <button className="minus-btn" onClick={downClick}>
        <i className="fas fa-minus"></i>
      </button>
    </div>
  );
};

export default Quantity;
