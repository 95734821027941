import React, { useReducer, useContext } from 'react';
import CustomersContext from './customersContext';
import customersReducer from './customersReducer';
import axios from 'axios';
import {
  CUSTOMERS_ERROR,
  CUSTOMERS_LOOKUP,
  GET_CUSTOMERS,
  SET_LOADING,
  GET_ALL_CUSTOMERS,
  CUSTOMER_ERROR,
  SET_CURRENT_CUSTOMER,
  CLEAR_CURRENT_CUSTOMER,
  GET_CUSTOMER_NOTES,
  GET_CUSTOMER_ORDER_NOTES,
  CREATE_CUSTOMER_NOTE,
  REMOVE_CURRENT_CUSTOMER,
  SEND_SMS
} from '../types';

// Create a custom hook to use the customers context
export const useCustomers = () => {
  const { state, dispatch } = useContext(CustomersContext);
  return [state, dispatch];
};

export const setLoading = (dispatch, isLoading) => dispatch({ type: SET_LOADING, payload: isLoading });

// Get Customers
export const getCustomers = async (dispatch, page, text, sort, excel=false) => {

  if (!excel) {
    setLoading(dispatch, true)
  }
  try {  
    
    const field = (sort.dir === 'desc') ? ('-' + sort.field) : sort.field;
    let query = '/v1/customers/?page=' + page + '&per-page=20&q=' + text + '&sort=' + field;
    // let query = '/v1/customers';
    
    if (excel) {
      query = query + '&forExcel=1';
    }

    const res = await axios.get(query);
    
    if (excel) {
      
      dispatch({
        type: GET_ALL_CUSTOMERS,
        payload: res.data.data
        });
        
      return res.data.data;   
    } else {
      dispatch({
        type: GET_CUSTOMERS,
        payload: res.data.data
      });
    }
  } catch (err) {
    dispatch({
      type: CUSTOMER_ERROR,
      payload: err.response ?? 'ארעה שגיאה'//.msg
    });
  }

  if (!excel) {
    setLoading(dispatch, false)
  }
};

const getCustomerNotes = async (dispatch, customer) => {
  try {
    const res = await axios.get('v1/customers/' + customer.id + '/notes');

    dispatch({
      type: GET_CUSTOMER_NOTES,
      payload: res.data.data.items
    });
  } catch (err) {
    dispatch({
      type: CUSTOMER_ERROR,
      payload: err.response ?? 'ארעה שגיאה'//.msg
    });
  }
};

const getCustomerOrderNotes = async (dispatch, customer) => {
  try {
    const res = await axios.get('v1/customers/' + customer.id + '/orders');

    dispatch({
      type: GET_CUSTOMER_ORDER_NOTES,
      payload: res.data.data.rows
    });
  } catch (err) {
    dispatch({
      type: CUSTOMER_ERROR,
      payload: err.response ?? 'ארעה שגיאה'//.msg
    });
  }
};

export const createNote = async (dispatch, id, note) => {
  try {
    const res = await axios.post('v1/customers/' + id + '/notes', note);
    dispatch({
      type: CREATE_CUSTOMER_NOTE,
      payload: res.data.data.item
    });
  } catch (err) {
    dispatch({
      type: CUSTOMER_ERROR,
      payload: err.response ?? 'ארעה שגיאה'//.msg
    });
  }
};

export const sendMessage = async (dispatch, id, text) => {
  try {
    const post = { customer: id, text: text };
    const res = await axios.post('v1/customers/send-message', post);

    dispatch({
      type: SEND_SMS,
      payload: res.data.data.item ?? []
    });
  } catch (err) {
    dispatch({
      type: CUSTOMER_ERROR,
      payload: err.response ?? 'ארעה שגיאה'//.msg
    });
  }
};

// Set Current Customer
export const setCurrent = (dispatch, customer) => {
  getCustomerNotes(dispatch, customer);
  getCustomerOrderNotes(dispatch, customer);
  dispatch({ type: SET_CURRENT_CUSTOMER, payload: customer });
};
export const removeCurrent = (dispatch) => {
  dispatch({ type: REMOVE_CURRENT_CUSTOMER});
};
// Clear Current Customer
export const clearCurrent = (dispatch) => {
  dispatch({ type: CLEAR_CURRENT_CUSTOMER });
};

export const getLookup = async (dispatch) => {
  try {
    const res = await axios.get('/v1/lookup/customers');

    dispatch({
      type: CUSTOMERS_LOOKUP,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: CUSTOMERS_ERROR,
      payload: err.response// err.response//.msg
    });
  }
};

const CustomersState = (props) => {
  const initialState = {
    customers: [],
    current: null,
    error: null,
    notes: [],
    orderNotes: [],
    count: 0,
    labels: [],
    columns: [],
    allCustomers: [],
    orderStatuses: [],
    loading: false
  };
  const [state, dispatch] = useReducer(customersReducer, initialState);

  return (
    <CustomersContext.Provider value={{ state: state, dispatch }}>
      {props.children}
    </CustomersContext.Provider>
  );
};

export default CustomersState;