import React, { Fragment } from 'react';
import { emptyOrders, useOrders, updateDelivery } from '../../context/orders/OrdersState';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useState } from 'react';
import {
    IntlProvider,
    LocalizationProvider
  } from "@progress/kendo-react-intl";
import { DatePicker } from "@progress/kendo-react-dateinputs";

const Title = (props) => {
    
    const [ordersState,ordersDispatch] = useOrders();
    const { currentOrder, customerName, deliveryDate, deliveryDateUnFormat, possibleDates, actions } = ordersState;
    const [updateDate, setUpdateDate] = useState(false);
    const myFormatter = new Intl.DateTimeFormat("fr-CA", {year: "numeric", month: "2-digit", day: "2-digit"});

    var lang = "he";

    // console.log(ordersState);
    const backToOrders=async()=>{
       await emptyOrders(ordersDispatch);
        props.navigate("/orders");
    }

    const defaultItem = {
        date: deliveryDateUnFormat,
        date_format: deliveryDate
    };

    const itemRender = (li, itemProps) => {
        const itemChildren = (
          <span date={itemProps.dataItem.date}>
            {itemProps.dataItem.date_format}
          </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };

    const onChangeDate = (e) => {
        console.log(e);
        updateDelivery(ordersDispatch, currentOrder.fields.id, e.value.date);
    }

    return (currentOrder &&
        <>
        <div className='title-div'>
           {isBrowser && <Fragment>
            <span className='title-text'>{customerName}</span>
            {(currentOrder.fields) && (
                <span>
                    <span> - הזמנה מספר </span>
                    <span className='title-text'> {currentOrder.fields.id} </span>
                    <span> לתאריך </span>
                    <Tooltip parentTitle={true} anchorElement="target" position="right">
                        {actions.includes("freeUpdateDate") ?
                        <>
                            <span>{deliveryDate + ' '}</span>
                            <i onClick={(e) => setUpdateDate(!updateDate)} className="cal-icon far fa-calendar " title="עדכן ת. משלוח"></i>
                            <LocalizationProvider language={lang}>
                            <IntlProvider locale={lang}>
                                <DatePicker
                        
                                format='yyyy-MM-dd'
                                defaultValue={new Date(deliveryDate.split('-')[2] + '-' + deliveryDate.split('-')[1] + '-' + deliveryDate.split('-')[0])}
                                onChange={(date) => {
                                    updateDelivery(ordersDispatch, currentOrder.fields.id, myFormatter.format(date.value));
                                    setUpdateDate(false);
                                }}
                                //value={{ start: defaultValue.start, end: defaultValue.end }}
                                style={{flex: '0 0 55%',
                                maxWidth: '75%',
                                position: 'absolute',
                                letf: '300px'}}
                                show={updateDate}
                                />
                            </IntlProvider>
                            </LocalizationProvider>
                        </>
                        :
                        <DropDownList
                        data={possibleDates.filter(current => {
                            return current.date_format !== deliveryDate;    // all possible dates except for current delivery date
                          })}
                        itemRender={itemRender}
                        onChange={onChangeDate}
                        dataKey="date"
                        textField="date_format"
                        defaultItem={defaultItem}
                        />
}
                    </Tooltip>
                </span>
                
            )}</Fragment>}
            {isMobile && <Fragment>
              <div className='back-btn-wrap'>  <button onClick={backToOrders} className='back-btn'><i className="fas fa-arrow-right"></i></button>
              </div>
                <div className='order-details'><span className='title-text customerName'>{customerName}</span>
                {(currentOrder.fields) && (
                    <div>
                        <span className='title-text'>{currentOrder.fields.id}</span>#
                        <span>&nbsp;</span>
                        
                        <Tooltip parentTitle={true} anchorElement="target" position="right">
                            
                            <DropDownList
                            data={possibleDates.filter(current => {
                                return current.date_format !== deliveryDate;    // all possible dates except for current delivery date
                            })}
                            itemRender={itemRender}
                            onChange={onChangeDate}
                            dataKey="date"
                            textField="date_format"
                            defaultItem={defaultItem}
                            />

                        </Tooltip>
                    </div>
                )}</div>
            </Fragment>}
            {(!isMobile && currentOrder.max_orders_monthly > 0) &&
            <span className="budget">
                <span>יתרת התקציב החודשי: </span>
                <span> {Math.round(Math.abs(currentOrder.budget_left))}{currentOrder.budget_left < 0 ? '-' : ''} </span>
                <span className="budget-sign">
                    <i className="fas fa-shekel-sign"></i>
                </span>
            </span>
            }
            {(!isMobile && currentOrder.max_order_weekly > 0) &&
            <span className="budget">
                <span>תקציב שבועי: </span>
                <span> {Math.round(Math.abs(currentOrder.max_order_weekly))}{currentOrder.max_order_weekly < 0 ? '-' : ''} </span>
                <span className="budget-sign">
                    <i className="fas fa-shekel-sign"></i>
                </span>
            </span>
            }
        </div>
        {(isMobile && (currentOrder.max_orders_monthly > 0 || currentOrder.max_order_weekly > 0)) &&
            <div className='title-div'>
            {currentOrder.max_orders_monthly > 0 &&    
            <span className="budget">
                <span>יתרת התקציב החודשי: </span>
                <span> {Math.round(Math.abs(currentOrder.budget_left))}{currentOrder.budget_left < 0 ? '-' : ''} </span>
                <span className="budget-sign">
                    <i className="fas fa-shekel-sign"></i>
                </span>
            </span>
            }
            {(currentOrder.max_order_weekly > 0) &&
            <span className="budget">
                <span>תקציב שבועי: </span>
                <span> {Math.round(Math.abs(currentOrder.max_order_weekly))}{currentOrder.max_order_weekly < 0 ? '-' : ''} </span>
                <span className="budget-sign">
                    <i className="fas fa-shekel-sign"></i>
                </span>
            </span>
            }
            </div>
        }
        </>
    )
}

export default Title
