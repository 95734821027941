import { useEffect, useState } from "react";
import popupStyles from "../../css/custom-popup.module.css"
import PropTypes from "prop-types";
import Customers  from "../customers/Customers";

const CustomersPopup = (props) => {
  const [show, setShow] = useState(false);

  const closeHandler = (e) => {
    if(e.target.className === popupStyles.overlay || e.target.className === popupStyles.close){
      setShow(false);
      props.onClose(false);
    }
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className={popupStyles.overlay}
      onClick={closeHandler}
    >
      <div className={[popupStyles.popup + ' customers_popup']}>
        <span className={popupStyles.close} onClick={closeHandler}>
          &times;
        </span>
        <Customers  from="popUp"/>    
        <div className={popupStyles.content}>{props.children}</div>
      </div>
    </div>
  );
};

CustomersPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
export default CustomersPopup;