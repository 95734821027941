import React, { useReducer, useContext } from "react";
import { isMobile } from "react-device-detect";
import OrdersContext from "./ordersContext";
import ordersReducer from "./ordersReducer";
import axios from "axios";
import {
  GET_ORDERS,
  GET_MOBILE_ORDERS,
  DUPLICATE_ORDER,
  SUBMIT_ORDER,
  CREATE_ORDER,
  SET_ORDER_SEARCH,
  TODAY_CLICKED,
  SET_CURRENT_ORDER,
  SET_ORDER_SEARCH_BY_FAMILY,
  GET_ORDERS_FOR_EXCEL,
  HIDE_SUCCESS_POP_UP,
  GET_ORDER_NOTES,
  GET_DEPARTMENTS,
  LOAD_ORDER,
  UPDATE_ORDER,
  GET_PL_PRODUCTS,
  ORDER_LOOKUP,
  UPDATE_PRODUCT,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  ORDERS_ERROR,
  CLEAR_SUCCESS,
  TOGGLE_DEP,
  TOGGLE_DAY,
  TOGGLE_LINE,
  TOGGLE_STATUS,
  FILTER_PRODUCTS_BY_FAMILY,
  FILTER_PRODUCTS_BY_TEXT,
  CLEAR_ERRORS,
  FILE_UPLOAD,
  UPLOAD_ORDERS,
  UPDATE_NOT_NOW_CREATE,
  CREATE_ORDER_NOTE,
  ADD_ERROR,
  ADD_SUCCESS,
  SET_LOADING,
  SEND_SMS,
  UPDATE_STATUS,
  UPDATE_DELIVERY,
  CUSTOMERS_POPUP,
  EMPTY_ORDERS,
  CLEAR_UPLOAD_MSG,
  SET_ENLARGED,
  ADD_FORBIDDEN_DATE,
  DELETE_FORBIDDEN_DATE,
  DATES_POPUP,
  UPDATE_MULTIPLE,
  UPDATE_PRODUCT_ERROR,
  SET_SEARCH,
  SET_SORT,
  CHECK_DELIVERY_DATE,
  HIDE_FORBIDDEN_POP_UP,
  SET_SEARCHING
} from "../types";

// Create a custom hook to use the orders context
export const useOrders = () => {
  const { state, dispatch } = useContext(OrdersContext);
  return [state, dispatch];
};

export const updateNotNoCreate = (dispatch) =>
  dispatch({ type: UPDATE_NOT_NOW_CREATE });

export const setLoading = (dispatch, isLoading) =>
  dispatch({ type: SET_LOADING, payload: isLoading });

export const clearErrors = (dispatch) => dispatch({ type: CLEAR_ERRORS });

export const clearSuccess = (dispatch) => dispatch({ type: CLEAR_SUCCESS });

export const addError = (dispatch, message) =>
  dispatch({ type: ADD_ERROR, message: message });

export const setShowCustomersPopup = (dispatch, show) =>
  dispatch({ type: CUSTOMERS_POPUP, payload: show });

export const setShowForbiddenDatesPopup = (dispatch, show) =>
  dispatch({ type: DATES_POPUP, payload: show });

export const AddSuccess = (dispatch, message) =>
  dispatch({ type: ADD_SUCCESS, message: message });

export const getOrders = async (
  dispatch,
  page,
  text,
  sort,
  dates,
  days,
  lines,
  statuses,
  excel = false,
  mobileTake = 10,
  years
) => {
  if (!excel) {
    setLoading(dispatch, true);
  }
  try {
   
    dispatch({
      type: SET_SEARCHING,
      payload: true,
    });

    let query = isMobile
      ? "/v1/orders/?page=" + page + "&per-page=" + mobileTake + "&q=" + text
      : "/v1/orders/?page=" + page + "&per-page=20&q=" + text;

    // adding sort
    if (sort) {
      const field = sort.dir === "desc" ? "-" + sort.field : sort.field;
      query = query + "&sort=" + field;
    }

    // search by range of dates
    if (dates && dates.length === 2) {
      query =
        query +
        "&delivery_from_date=" +
        dates[0] +
        "&delivery_to_date=" +
        dates[1];
    }
    if (years) {
      query = query + '&year=' + years;
    }
    // search by selected days
    const searchDays = days
      .filter((day) => day.active === 1)
      .map((activeDay) => activeDay.date);

    if (searchDays && searchDays.length > 0) {
      query = query + "&delivery_date=" + searchDays;
    }

    // search by seleceted lines
    const searchLines = lines
      .filter((line) => line.active === 1)
      .map((activeLine) => activeLine.id);

    if (searchLines && searchLines.length > 0) {
      query = query + "&line=" + searchLines;
    }
    const searchStatus = statuses
      .filter((status) => status.active === 1)
      .map((activeStatus) => activeStatus.id);
    if (searchStatus && searchStatus.length > 0) {
      query = query + "&status=" + searchStatus;
    }

    if (excel) {
      query = query + "&forExcel=1";
    }

    const res = await axios.get(query);

    if (excel) {
      dispatch({
        type: GET_ORDERS_FOR_EXCEL,
        payload: res.data.data,
      });
      return res.data.data;
    } else if (isMobile) {
      dispatch({
        type: GET_MOBILE_ORDERS,
        payload: { data: res.data.data, length: (page - 1) * mobileTake },
      });
    } else {
      dispatch({
        type: GET_ORDERS,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err);

    dispatch({
      type: ORDERS_ERROR,
      payload: err.response ?? "ארעה שגיאה",
    });
  }

  if (!excel) {
    setLoading(dispatch, false);
  }
};

export const duplicateOrder = async (dispatch, orderId) => {
  
  try {
    setLoading(dispatch, true);
    const res = await axios.post("/v1/orders/duplicate-order", {
      order_id: orderId,
    });
    dispatch({
      type: DUPLICATE_ORDER,
      payload: res.data.data,
    });
    setLoading(dispatch, false);
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
    setLoading(dispatch, false);
  }
  
};
export const emptyOrders = (dispatch) => {
  dispatch({ type: EMPTY_ORDERS });
}
export const uploadOrderProducts = async (dispatch, formData, orderId) => {
  try {
    const res = await axios.post("/v1/orders/import-products", formData);
    getPriceListProducts(dispatch, orderId);
    dispatch({
      type: FILE_UPLOAD,
      payload: res.data.data ?? "ארעה שגיאה", //.msg
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response
        ? err.response.data.title ?? "ארעה שגיאה"
        : "ארעה שגיאה",
    });
  }
  return false;
};

export const uploadOrders = async (
  dispatch,
  formData,
  text,
  dates,
  days,
  lines,
  statuses
) => {
  let query = "/v1/orders/import-orders/?q=" + text;

  // search by range of dates
  if (dates && dates.length === 2) {
    query =
      query +
      "&delivery_from_date=" +
      dates[0] +
      "&delivery_to_date=" +
      dates[1];
  }

  // search by selected days
  const searchDays = days
    .filter((day) => day.active === 1)
    .map((activeDay) => activeDay.date);

  if (searchDays && searchDays.length > 0) {
    query = query + "&delivery_date=" + searchDays;
  }

  // search by seleceted lines
  const searchLines = lines
    .filter((line) => line.active === 1)
    .map((activeLine) => activeLine.id);

  if (searchLines && searchLines.length > 0) {
    query = query + "&line=" + searchLines;
  }
  const searchStatus = statuses
    .filter((status) => status.active === 1)
    .map((activeStatus) => activeStatus.id);
  if (searchStatus && searchStatus.length > 0) {
    query = query + "&status=" + searchStatus;
  }

  try {
    setLoading(dispatch, true);
    const res = await axios.post(query, formData);
    console.log(res);
    dispatch({
      type: UPLOAD_ORDERS,
      payload: res.data ?? "ארעה שגיאה", //.msg
    });
    setLoading(dispatch, false);
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response
        ? err.response.data.title ?? "ארעה שגיאה"
        : "ארעה שגיאה",
    });
  }
  return false;
};

//saves the current text of the search in order page
export const setOrderSearch = async (dispatch, text) => {
  dispatch({
    type: SET_ORDER_SEARCH,
    payload: text,
  });
};

//saves the current id of the searchd department
export const setOrderSearchFamily = async (dispatch, familyId) => {
  dispatch({
    type: SET_ORDER_SEARCH_BY_FAMILY,
    payload: familyId,
  });
};

export const searchByFamily = async (dispatch, orderId, id) => {
  try {
    let query = "/v1/orders/" + orderId + "/price-list-products";
    if (id) {
      query += "?family=" + id;
    }
    const res = await axios.get(query);
    dispatch({
      type: FILTER_PRODUCTS_BY_FAMILY,
      payload: res.data.data.rows,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response
        ? err.response.data.title ?? "ארעה שגיאה"
        : "ארעה שגיאה",
    });
  }
};

export const searchByText = async (dispatch, id, text) => {
  try {
    const res = await axios.get(
      "/v1/orders/" + id + "/price-list-products?q=" + text
    );

    dispatch({
      type: FILTER_PRODUCTS_BY_TEXT,
      payload: res.data.data.rows,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response
        ? err.response.data.title ?? "ארעה שגיאה"
        : "ארעה שגיאה",
    });
  }
};

export const loadOrder = async (dispatch, id) => {
  try {
    const res = await axios.get("/v1/orders/" + id);

    dispatch({
      type: LOAD_ORDER,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
  }
};

export const checkDeliveryDate = async (dispatch, id) => {
  try {
    const post = { id: id};
    const res = await axios.post("v1/orders/check-delivery-date", post);
    
    console.log(res);
    dispatch({
      type: CHECK_DELIVERY_DATE,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
  }
};

export const createOrder = async (dispatch, customerId) => {
  try {
    const res = await axios.post("/v1/orders", { customerId: customerId });

    dispatch({
      type: CREATE_ORDER,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response.data.title ?? "error", // err.response//.msg
    });
  }
};

export const submitOrder = async (
  dispatch,
  orderId,
  deliveryDate,
  customerId,
  products
) => {
 
  try {
    setLoading(dispatch, true);
    let res = await axios.post("/v1/orders/submit-order", {
      orderId: orderId,
      customerId: customerId,
      deliveryDate: deliveryDate,
      products: products,
    });
    console.log(res);
    if (res && res?.data?.data?.success) {
      dispatch({
        type: SUBMIT_ORDER,
        payload: res.data,
      });
    } else {
      dispatch({
        type: ORDERS_ERROR,
        payload: "ארעה שגיאה",
      });
    }
    setLoading(dispatch, false);
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: "ארעה שגיאה",
    });
    setLoading(dispatch, false);
  }
  
};

export const updateOrder = async (dispatch, orderId) => {
  
  try {
    setLoading(dispatch, true);
    let res = await axios.post("/v1/orders/update-products", {
      orderId: orderId,
    });
    dispatch({
      type: UPDATE_ORDER,
      payload: res.data,
    });
    setLoading(dispatch, false);
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: "ארעה שגיאה",
    });
    setLoading(dispatch, false);
  }
  
};

export const getLookup = async (dispatch) => {
  try {
    const res = await axios.get("/v1/lookup/orders");

    dispatch({
      type: ORDER_LOOKUP,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response?.data.title ?? "", // err.response//.msg
    });
  }
};

export const getOrderNotes = async (dispatch, order) => {
  try {
    let orderId = order.id ? order.id : order.fields.id;
    const res = await axios.get("v1/orders/" + orderId + "/notes");
    dispatch({
      type: GET_ORDER_NOTES,
      payload: res.data.data.items,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response ?? "ארעה שגיאה", //.msg
    });
  }
};

/**
 * craete note for order
 * @param {*} dispatch
 * @param {*} id
 * @param {*} note
 */
export const createNote = async (dispatch, id, note) => {
  try {
    const res = await axios.post("v1/orders/" + id + "/notes", note);
    dispatch({
      type: CREATE_ORDER_NOTE,
      payload: res.data.data.item,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response ?? "ארעה שגיאה", //.msg
    });
  }
};

/**
 * send message to order's customer
 * @param {*} dispatch
 * @param {*} id
 * @param {*} text
 */
export const sendMessage = async (dispatch, id, text) => {
  try {
    const post = { order: id, text: text };
    const res = await axios.post("v1/orders/send-message", post);

    dispatch({
      type: SEND_SMS,
      payload: res.data.data.item ?? [],
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response ?? "ארעה שגיאה", //.msg
    });
  }
};

// Set Current Order
export const setCurrent = (dispatch, order) => {
  getOrderNotes(dispatch, order);
  getPriceListProducts(dispatch, order.id);
  dispatch({ type: SET_CURRENT_ORDER, payload: order });
};

export const removeCurrent = (dispatch) => {
  dispatch({ type: SET_CURRENT_ORDER });
};

export const clearUploadMessages = (dispatch) => {
  dispatch({
    type: CLEAR_UPLOAD_MSG
  })
}

// get products from pricelist for order
export const getPriceListProducts = async (dispatch, id) => {
  try {
    const res = await axios.get("/v1/orders/" + id + "/price-list-products");

    dispatch({
      type: GET_PL_PRODUCTS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
  }
};

export const getDepartments = async (dispatch) => {
  try {
    const res = await axios.get("/v1/lookup/orders");

    dispatch({
      type: GET_DEPARTMENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
  }
};

export const updateStatus = async (dispatch, orderId, status) => {
  try {
    const res = await axios.put("/v1/orders/" + orderId, { status: status });

    dispatch({
      type: UPDATE_STATUS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
  }
};

export const updateDelivery = async (dispatch, orderId, delivery) => {
  setLoading(dispatch, true);
  
  try {
    const res = await axios.put("/v1/orders/" + orderId, { delivery_date: delivery });
    
    // get order again (budget etc)
    loadOrder(dispatch, orderId);

    dispatch({
      type: UPDATE_DELIVERY,
      payload: res.data.data,
    });
    
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
  }
  setLoading(dispatch, false);
};
 
export const updateMultiple=async (dispatch,ids,value)=>{
  setLoading(dispatch, true);
  try {
    const res = await axios.post("/v1/orders/update-multiple" , {ids:ids ,...value });
    
    
    dispatch({
      type: UPDATE_MULTIPLE,
      payload: res.data.data,
    });
    

  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
  }
  setLoading(dispatch, false);

}
export const updateProduct = async (
  dispatch,
  orderId,
  product,
  server,
  oldQuantity = null
) => {
  
  try {
    let order;
    dispatch({ type: SET_LOADING, payload: true });
    console.log("update product after set loading");
    
    if (server) {
      const res = await axios.put(
        "/v1/orders/" + orderId + "/products/"  + product.id,
        product
      );
      console.log(res);
      order = res.data.data.model;
    } else {
      order = null;
    }

    // total amount will be set according to order or to quantity diff
    dispatch({
      type: UPDATE_PRODUCT,
      payload: {
        product: product,
        order: order ?? null,
        oldQuantity: oldQuantity,
      },
    });
    dispatch({ type: SET_LOADING, payload: false });
  } catch (err) {
    console.log(err);
    dispatch({
      type: UPDATE_PRODUCT_ERROR,
      payload: {'error': err.response, 'product': product}//.msg
    });
    setLoading(dispatch, false);
  }
};

export const addProduct = async (
  dispatch,
  orderId,
  product,
  server,
  client
) => {
  
  try {
    setLoading(dispatch, true);
    if (server) {
      await axios.put(
        "/v1/orders/" + orderId + "/products/" + product.id,
        product
      );
      //const res = await axios.post('/v1/orders/' + orderId + '/products', product);
    }

    if (client) {
      dispatch({
        type: ADD_PRODUCT,
        payload: { product: product },
      });
    }
    setLoading(dispatch, false);
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
    setLoading(dispatch, false);
  }
  
};

export const deleteProduct = async (dispatch, orderId, product) => {
  setLoading(dispatch, true);
  try {
    
    const res = await axios.put(
      "/v1/orders/" + orderId + "/products/" + product.id,
      product
    );
    //const res = await axios.delete('/v1/orders/' + orderId + '/products/' + productId);
    console.log(res);
    dispatch({
      type: DELETE_PRODUCT,
      payload: { order: res.data.data.model, productId: product.id },
    });
    
  } catch (err) {
    console.log(err);
    dispatch({
      type: ORDERS_ERROR,
      payload: err.response, //.msg
    });
    
  }
  setLoading(dispatch, false);
};

export const toggleDepartment = (dispatch, department) => {
  dispatch({
    type: TOGGLE_DEP,
    payload: department,
  });
};

export const hideSuccessPopUp = (dispatch) => {
  dispatch({
    type: HIDE_SUCCESS_POP_UP,
  });
};

export const hideForbiddenNotice = (dispatch) => {
  dispatch({
    type: HIDE_FORBIDDEN_POP_UP,
  });
};


export const toggleStatus = (dispatch, status) => {
  dispatch({
    type: TOGGLE_STATUS,
    payload: status,
  });
};
export const toggleDay = (dispatch, day) => {
  dispatch({
    type: TOGGLE_DAY,
    payload: day,
  });
};

export const toggleLine = (dispatch, line) => {
  dispatch({
    type: TOGGLE_LINE,
    payload: line,
  });
};

// stop todya's day to be selected in search slider
export const setTodayWasClicked = (dispatch) => {
  dispatch({
    type: TODAY_CLICKED,
  });
};

// set enlarged product image
export const setEnlarged = (dispatch, product) => {
  dispatch({
    type: SET_ENLARGED,
    payload: product
  });
};


// Add forbidden date
export const addForbiddenDate = async (dispatch, forbidden) => {

  try {
    setLoading(dispatch, true);

    if (forbidden !== '') {
      const res = await axios.post("/v1/orders/add-forbidden-date", {
        xxxx: forbidden,
      });
    }
    dispatch({
      type: ADD_FORBIDDEN_DATE,
      payload: forbidden
    });
    setLoading(dispatch, false);
  } catch (err) {
    dispatch({
      type: ORDERS_ERROR,
      payload: "ארעה שגיאה"
    });
    setLoading(dispatch, false);
  }
};

export const setSearch = async (dispatch, search) => {
  console.log(search);
  console.log("in set search!!!");
  dispatch({
    type: SET_SEARCH,
    payload: search
  });
}

export const setSort = async (dispatch, sort) => {
  dispatch({
    type: SET_SORT,
    payload: sort
  });
}

// Add forbidden date
export const deleteForbiddenDate = async (dispatch, forbidden) => {

  try {
    setLoading(dispatch, true);

    if (forbidden !== '') {
      const res = await axios.post("/v1/orders/delete-forbidden-date", {
        f_date: forbidden,
      });
    }
    dispatch({
      type: DELETE_FORBIDDEN_DATE,
      payload: forbidden
    });
    setLoading(dispatch, false);
  } catch (err) {
    console.log(err);
    dispatch({
      type: ORDERS_ERROR,
      payload: "ארעה שגיאה"
    });
    setLoading(dispatch, false);
  }
};

const OrdersState = (props) => {

  const initialState = {
    orders: [],
    current: null,
    customerName: "",
    deliveryDate: "",
    count: 0,
    currentOrder: {},
    createdNow: false,
    submittedNow: false,
    updatedNow: false,
    statusNow: false,
    forbiddenNotice: 0,
    departments: [],
    statuses: [],
    columns: [],
    actions: [],
    labels: [],
    families: [],
    lines: [],
    days: [],
    years: [],
    cancelledStatus: null,
    noNeedStatus: null,
    sentStatus: null,
    draftStatus: null,
    missingNoteType: null,
    customerId: null,
    openCustomers: false,
    sendMessages: false,
    currentDepartment: null,
    currentFamily: null,
    products: [],
    plProducts: [],
    relevantDeps: [],
    relevantFamilies: [],
    currentProduct: {},
    total: 0,
    widths: [],
    notes: [],
    error: "",
    successPopUp: false,
    lastSubmitted: {},
    success: "",
    uploadMessages: [],
    loading: false,
    todayWasClicked: false,
    orderSearchText: "",
    orderSearchFamily: 0,
    allOrdersForExcel: [],
    showCustomersPopup: false,
    showForbiddenDatesPopup: false,
    deliveryDateUnFormat: "",
    possibleDates: [],
    enlarged: null,
    existingOrder: null,
    forbiddenDates: [],
    searching: false,
    showPrices: false,
    ordersSearch: {
      dates: [],
      days: [],
      lines: [],
      pageNumber: 1,
      search: "",
      skip: 0,
      take: 20,
      statuses: [],
      years: ""
    },
    ordersSort: [{
      field: "line",
      dir: "asc",
    }]
  };

  const [state, dispatch] = useReducer(ordersReducer, initialState);

  return (
    <OrdersContext.Provider value={{ state: state, dispatch }}>
      {props.children}
    </OrdersContext.Provider>
  );
};

export default OrdersState;
