import React, {useReducer, useContext,} from 'react';
import ProductsContext from './productsContext';
import productsReducer from './productsReducer';
import axios from 'axios';
import {
    GET_PRODUCTS,
    PRODUCT_ERROR,
    UPDATE_LINES,
    TOGGLE_DAY,
    PRODUCTS_LOOKUP,
    CLEAR_ERRORS,
    SET_LOADING,
    CLEAR_SUCCESS,
    SET_QUENTITY,
    DELETE_LINES,
    CLEAR_CHANGES
} from '../types';


// Create a custom hook to use the products context
export const useProducts = () => {
   const { state, dispatch } = useContext(ProductsContext);
  return [state, dispatch];
};

export const setLoading = (dispatch, isLoading) => dispatch({ type: SET_LOADING, payload: isLoading });


// Get Products
export const getProducts = async (dispatch, text, sort, days) => {
    setLoading(dispatch, true)
    try {
      const field = (sort.dir === 'desc') ? ('-' + sort.field) : sort.field;
      let query ='/v1/products/order-lines/?getAll=1&q=' + text + '&sort=' + field;
      if(days){
        const searchDays = days.filter(day => day.active === 1).map(activeDay => activeDay.date);

        if (searchDays && searchDays.length > 0) {
            query = query + '&delivery_date=' + searchDays;
        }
      }
      const res = await axios.get(query);

      dispatch({
        type: GET_PRODUCTS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: PRODUCT_ERROR,
        payload: err.response ?? 'ארעה שגיאה'//.msg
      });
    }
    setLoading(dispatch, false)
};

export const getLookup = async (dispatch) => {
    try {
      const res = await axios.get('/v1/lookup/products-order-lines');
  
      dispatch({
        type: PRODUCTS_LOOKUP,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: PRODUCT_ERROR,
        payload: 'err.response// err.response//.msg'
      });
    }
};

export const deleteLines = async (dispatch, lines, search, days) => {
  try {
    let query ='/v1/products/remove-from-orders?q=' + search ;
    if(days){
      const searchDays = days.filter(day => day.active === 1).map(activeDay => activeDay.date);

      if (searchDays && searchDays.length > 0) {
          query = query + '&delivery_date=' + searchDays;
      }
    }
    const res = await axios.post(query, lines);

    dispatch({
      type: DELETE_LINES,
      payload: res.data.data,
      length: lines.order_lines.length
    });
  } catch (err) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: 'err.response// err.response//.msg'
    });
  }
  //return false to stop loading
  return false;
};

export const updateQuantitys = async (dispatch, lines, search, sort, days) => {
  try {
    const field = (sort.dir === 'desc') ? ('-' + sort.field) : sort.field;
    let query ='/v1/products/update-quantities?q=' + search + '&sort=' + field;
    if(days){
      const searchDays = days.filter(day => day.active === 1).map(activeDay => activeDay.date);

      if (searchDays && searchDays.length > 0) {
          query = query + '&delivery_date=' + searchDays;
      }
    }
    const res = await axios.post(query, lines);
    dispatch({
      type: UPDATE_LINES,
      payload: res.data.data
    });
  }catch (err) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: 'err.response// err.response//.msg'
    });
  }
  //return false to stop loading
  return false;
};

export const toggleDay = (dispatch, day) => {
  dispatch({
      type: TOGGLE_DAY,
      payload: day
  });
}

export const setProductQuantity = (dispatch, product) => {
  dispatch({
      type: SET_QUENTITY,
      payload: product
  });
}

export const clearChanges = (dispatch) => {
  dispatch({
    type: CLEAR_CHANGES
  });
}

export const clearErrors = (dispatch) => dispatch({ type: CLEAR_ERRORS });

export const clearSuccess = (dispatch) => dispatch({ type: CLEAR_SUCCESS  });


const ProductsState = (props) => {

    const initialState = {
        error: '',
        products: [],
        copy: [],
        columns: [],
        days: [],
        labels: [],
        todayWasClicked: false,
        count: '',
        loading: false,
        success: ''
    };

    const [state, dispatch] = useReducer(productsReducer, initialState);


    return (
        <ProductsContext.Provider value={{ state: state, dispatch }}>
        {props.children}
        </ProductsContext.Provider>
    );
};

export default ProductsState;