import React, { useEffect, useState, useRef } from 'react';
import CustomerItem from './CustomerItem';
import { useNavigate } from 'react-router-dom';
import { Grid, GridColumn, getSelectedState, GridNoRecords } from '@progress/kendo-react-grid';
import { Loader } from "@progress/kendo-react-indicators";
import { getter } from "@progress/kendo-react-common";
import { useCustomers, getCustomers, setCurrent, getLookup } from '../../context/customers/CustomersState';
import { useOrders, createOrder, getLookup as ordersLookup } from "../../context/orders/OrdersState";
import { ExcelExport } from '@progress/kendo-react-excel-export';

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const Customers = (from) => {
    const [customersState, customersDispatch] = useCustomers();
    const { customers, columns, labels, error, count, allCustomers, loading } = customersState;
    const [ordersState, ordersDispatch] = useOrders();
    const { currentOrder, createdNow } = ordersState;
    const navigate = useNavigate();
    // state is used for pagination and search
    const createState = (skip, take, search) => {
        return {
            pageNumber: skip / take + 1,
            skip: skip,
            take: take,
            search: search
        };
    };
    const [selectedState, setSelectedState] = React.useState({});
    const onSelectionChange = React.useCallback(
        (event) => {
            if(!loading){
                const newSelectedState = getSelectedState({
                    event,
                    selectedState: selectedState,
                    dataItemKey: DATA_ITEM_KEY,
                });
                setSelectedState(newSelectedState);
            }
        },
        // eslint-disable-next-line
        [selectedState]
    );

    // sorting data
    const initialSort = [
        {
            field: "id",
            dir: "asc",
        },
    ];

    const [state, setState] = useState(createState(0, 20, ''));
    const [sort, setSort] = useState(initialSort);

    // setting pagination
    const pageChange = (event) => {
        setState(createState(event.page.skip, event.page.take, state.search, state.sort));
    };

    // setting sort
    const sortClick = (event) => {
        setSort(event.sort);
    };

    // setting search
    const onChange = e => {
        setState(createState(0, state.take, e.target.value));
    }

    const onSubmit = e => {
        e.preventDefault();
    }

    const create = e => {
        createOrder(ordersDispatch, Object.keys(selectedState)[0]);
    };

    // getting lookup at the first load
    useEffect(() => {
        console.log("customers lookup!!!");
        if (!columns.length) {
            getLookup(customersDispatch);
        }
    }, [customersDispatch]);

    // getting customers of current page with search and sort
    useEffect(() => {
        getCustomers(customersDispatch, state.pageNumber, state.search, sort[0]);
    }, [customersDispatch, state.pageNumber, state.search, sort]);
    
    useEffect(() => {
        if (createdNow && currentOrder !== null) {
            navigate('/orders/' + currentOrder['id']);
        }
        
        //ordersLookup(ordersDispatch);
    }, [createdNow]);

    // if UnAuthorized - refresh page
    useEffect(() => {
        if (typeof error !== 'undefined' && 
            error !== null && 
            typeof error.data !== 'undefined' &&
            error.data.status === 401) {
            window.location.reload();
        }
    }, [error]);
    
    const _export = useRef(null);
    const _grid = useRef();
    const [isLoadinExcel, setIsLoadingExcel] = useState(false);

    const exportToExcel = () => {     
        setIsLoadingExcel(true)

        //get all customers for export to excel
        getCustomers(customersDispatch, null, state.search, sort[0], true).then (
            res => {
                console.log(res)
                if (_export.current !== null && res) {
                    _export.current.save(res.rows, _grid.current.columns);
                }
                setIsLoadingExcel(false)
            }
        )
    };

    return (
        <div className='divAllPage customersPage '>
            <div className='divAllPage_R'>
                <div className='search searchDisplay'>

                    <div className="search_wrapper">
                        <form onSubmit={onSubmit}>
                            <div className='searchNameId'>
                                <i className="fas fa-search"></i>
                                <input className='textCss nameIdInput' type="text" placeholder="הקלידו שם לקוח / ID" onChange={onChange} />
                            </div>
                        </form>

                    </div>
                    <button id='btnExcel' className='btn' onClick={exportToExcel} disabled={isLoadinExcel}>
                        {isLoadinExcel ? 
                            <Loader type="pulsing"/> :
                            [<i className="fas fa-file-excel" key={1} ></i>, "ייצוא לאקסל"]
                        }
                    </button>
                </div>
                <div className='divWarpGrid'>
                    <div className={loading ? 'loading divWarpGrid_wrapper' : 'divWarpGrid_wrapper'} dir='rtl'>
                        {loading && <Loader type="infinite-spinner"/>}
                        <ExcelExport data={allCustomers ?? [] } ref={_export}>
                            <Grid
                                data={customers.map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                                }))}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "multiple",
                                }}
                                onSelectionChange={onSelectionChange}
                                skip={state.skip}
                                take={state.take}
                                total={count}
                                sort={sort}
                                pageable={{
                                    buttonCount: 10,
                                    info: true,
                                    type: "numeric",
                                    previousNext: true,
                                }}
                                onPageChange={pageChange}
                                sortable={true}

                                onSortChange={sortClick}
                                onRowDoubleClick={from.from && create}
                                onRowClick={(event) => {
                                    !from.from && !loading && setCurrent(customersDispatch, event.dataItem);
                                }}
                                ref={_grid}
                            >
                                <GridNoRecords>     
                                    <div>
                                        <i className="fas fa-search"></i>   לא נמצאו תוצאות
                                    </div>                      
                                </GridNoRecords>    
                                {(from.from ? (columns.slice(0, 2) ?? []) : (columns ?? [])).map((column) =>
                                    <GridColumn key={column} field={column} title={labels[column]} />
                                )}
                            </Grid>
                        </ExcelExport>
                    </div>
                </div>
                {from.from && <button className="new-order-btn btn" onClick={create} >המשך לבחירת מוצרים</button>}
            </div>
            {!from.from &&
                <div className='divAllPage_L'>
                    <CustomerItem />
                </div>
            }
        </div>
    );
}
export default Customers


