import React, { useState, useEffect } from 'react';
import {
  useOrders,
  uploadOrderProducts,
  addError,
} from '../../context/orders/OrdersState';
import { Loader } from "@progress/kendo-react-indicators";
import exampleFile from "../../files/קובץ לדוגמא.xlsx"

const UploadFile = () => {
  const [ordersState, ordersDispatch] = useOrders();
  const { currentOrder} = ordersState;
  const [state, setState] = useState({ selectedFile: '' });
  const [isFileLoading, setIsFileLoading] = useState(false);
  useEffect(() => {
    if(state.selectedFile){
      onFileUpload();
    }
    //eslint-disable-next-line
  },[state]);

  const onFileChange = (e) => {
    setIsFileLoading(true)
    if(e.target.files.length > 0) {
      var fileTypeArr = ["text/comma-separated-values", "text/csv", "application/csv", "application/vnd.ms-excel", 'xlsx', 'xls', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      if ( fileTypeArr.indexOf(e.target.files[0].type) < 0){
        setIsFileLoading(false)
        addError(ordersDispatch,'נא להעלות קובץ מסוג csv או excel');
      }else{
        setState({ selectedFile: e.target.files[0] });
      }
    }
  };

  const onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();
    if (state.selectedFile) {
        // Update the formData object
        formData.append('orderId', currentOrder.fields.id);
        formData.append('myFile', state.selectedFile, state.selectedFile.name);
        uploadOrderProducts(ordersDispatch, formData, currentOrder.fields.id).then(val => {
          setIsFileLoading(val)
        })
    }
  };

  return (
    <div className='form-group fileUploadSection'>
      <div className='input-group-btn'>
        {
          //  style={{ opacity: loading ? 0.7 : 1 }}
          <span className='fileUpload'>
                  
            {isFileLoading ?  
            <Loader type="pulsing"/> :
              <span className='upl' id='upload'>          
                  <i className="fas fa-file-upload"></i>אקסל מוצרים       
              </span>
            }
              <input
              type='file'
              className='upload up'
              id='up'
              onChange={onFileChange}
              disabled= {isFileLoading}
              accept=".xlsx, .xls, .csv, , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
          </span>
        }
      </div>
      <a href={exampleFile} download>
        <i className="fas fa-file-download"></i>
          קובץ לדוגמא
        </a>
    </div>
  );
};

export default UploadFile;
